@import '~assets/sass/colors';

.o-boardView {
    margin: 25px 0px 0px 0px;

    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__taskGroupsContainer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        overflow-x: auto;
        align-items: stretch;
        height: 560px;
        margin-top: 20px;

        > div {
            margin-right: 20px;
        }

        > div:last-child {
            margin-right: 0px;
        }
    }

    &__emptyData {
        margin: 20px 0px;

        &.-header {
            color: get-color(--text-primary);
            margin: 0;
            font-size: var(--font-size-xlarge);
            font-weight: 600;
        }

        &.-message {
            color: get-color(--text-secondary);
            font-size: var(--font-size-normal);
            margin: 5px 0px 20px 0px;
        }
    }
}
