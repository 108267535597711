@import '~assets/sass/colors';

.o-estimateTasks {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        margin: 0px 0px 20px 0px;

        button:first-child {
            margin-right: 10px;
        }
    }

    &__headings {
        margin-bottom: 20px;
    }

    &__body {
        margin-top: 35px;
    }

    span {
        font-size: 14px;
        color: var('--text-primary');
        transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &__taskGroup-list {
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
        }
    }
}

.m-taskEstimateForm {
    &__details {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        margin-top: 35px;
        & > button:first-child {
            margin-right: 10px;
        }
        & > button:last-child {
            margin-left: 50%;
            margin-top: 10px;
        }
    }

    &__userContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__label {
        color: #1e3251;
        font-size: 14px;

        &:first-child {
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 14px;
        color: #1e3251;
        font-style: italic;
    }
}

.m-estimateTasksTable {
    .foo-in {
        border: 3px solid red !important;
    }

    .foo-out {
        border: 3px solid blue !important;
    }

    &__item {
        width: 100%;
        height: 68px;
        align-items: center;
        border-bottom: 1px solid var(--light-blue-gray);
        background-color: white;

        &:last-child {
            border-bottom: none;
        }

        &__name {
            font-weight: bold;
            text-align: left;
        }

        &__user {
            display: flex;
            justify-content: center;
            align-items: center;
            .profile_picture_icon {
                width: 50px;
            }
        }

        &__amount {
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: space-between;

            input {
                text-align: right;
                padding: 9px 14px;
            }

            span:first-child {
                font-weight: bold;
                float: left;
                padding-right: 5px;
            }
        }

        .MuiOutlinedInput-input {
            padding: 9px 14px;
        }

        .--grayed-out {
            span:last-child {
                opacity: 0.5;
            }
            > div {
                opacity: 0.5;
            }
            input {
                opacity: 0.5;
            }
            input[aria-hidden='true'] {
                opacity: 0;
            }
        }
    }

    &__tableHeader {
        background-color: get-color(--gray-800);
        color: white;
        font-weight: var(--font-weight-bold);
        &__sh {
            text-align: center;
        }
    }
}

.m-totalEstimateBar {
    margin-bottom: 30px;

    &__graph {
        border: 1px solid #667989;
        border-radius: 6px;
        height: 50px;
        overflow: hidden;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: stretch;
        align-self: flex-start;
        > div {
            font-weight: bold;
            padding: 3px 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-right: 1px solid #667989;
            transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &:last-child {
                border-right: 0px;
            }
        }
        > span {
            width: auto;
        }
    }
    &__totals {
        font-weight: bold;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: right;
    }
}

.m-whatAreCategories {
    &__heading {
        background-color: get-color(--divider);
        font-size: 16px;
        font-weight: var(--font-weight-semibold);
        padding: 10px 13px;
        box-shadow: inset 0px 2px 2px -1px #23262659;
    }

    ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        li {
            padding: 13px;
            border-bottom: 1px solid #e0e2e3;
        }
    }

    p {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    &__divider {
        display: block;
        font-size: 16px;
        font-weight: var(--font-weight-semibold);
        padding: 10px 13px;
        border-bottom: 1px solid #e0e2e3;
        background-color: get-color(--divider);
    }

    &__item {
        &__title {
            font-weight: var(--font-weight-bold);
            font-size: 14px;
            text-transform: uppercase;
            display: block;
            line-height: 24px;
        }
        &__subTitle {
            display: block;
            font-size: 14px;
            font-weight: var(--font-weight-semibold);
            line-height: 20px;
        }
    }
}

.m-estimatesByCategory {
    tr {
        vertical-align: top;
        border-bottom: 1px solid #e0e2e3;
    }

    &__emptyTasks {
        box-shadow: inset 0px 2px 2px -1px #23262659;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-style: italic;
    }

    &__name {
        font-weight: var(--font-weight-semibold) !important;
        border-right: 1px solid #e0e2e3;
    }
    &__numbers {
        span {
            font-weight: var(--font-weight-bold);
        }
    }
}
