@import '~assets/sass/colors';

.o-navigation {
    height: 68px;
    display: flex;
    padding: 0px 15px;
    background-color: white;
    border-bottom: 3px solid get-color(--divider);

    margin: 0 -28px;

    @media (min-width: 1280px) {
        margin: 0 -38px;
    }

    @media (min-width: 1920px) {
        margin: 0 -57px;
    }
}

.o-nav {
    width: 100%;
    height: 100%;

    &__list {
        margin: 0;
        height: 100%;
        padding: 0;
        list-style: none;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 40px;

        &.horizontal_menu {
            display: flex;
            align-items: center;
        }

        > .o-nav__item:last-child {
            padding-right: 2rem;
            margin: 0 0 0 auto;
        }
    }

    &__link {
        padding: 0 0 3px 0;
        color: get-color(--text-primary);
        &:hover {
            font-weight: var(--font-weight-bold);
        }
        &.-active {
            color: get-color(--text-primary);
            font-weight: var(--font-weight-bold);
            border-bottom: 3px solid get-color(--secondary-main);
            padding-bottom: 0px;
        }
    }

    &__item {
        cursor: pointer;
        height: 100%;
        perspective: 1000px;

        a {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: get-color(--black-hsl);
            border-bottom: 0;

            > div {
                display: flex;
                height: 100%;
                align-items: center;
            }

            &:hover {
                color: get-color(--secondary-main);
            }
        }

        a:first-child {
            justify-content: flex-start;
        }
    }
}
