@import '~assets/sass/colors';

.o-alignResources {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__filter {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 1em;

        p {
            margin: 0;
        }
    }

    &__closeIcon {
        .MuiIcon-root {
            font-size: 25px;
        }
    }

    &__resourceBreakdownTableContainer {
        padding: 16px;
        width: 100%;
    }
}
