$row-height: 42px;

.Gantt {
    height: 100%;
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    &__filters {
        display: flex;
        justify-content: space-between;
    }

    &__groupBy,
    &__timeBy {
        &__select.MuiFormControl-root {
            margin: 10px 0px;

            label {
                transform: translate(0, -20px) scale(1);
                color: #838585;
                font-size: 12px;
                padding-left: 10px;
            }
            .MuiInputBase-root {
                background-color: rgb(255, 255, 255);
                border: 1px solid #0000001f;
                border-radius: 4px;
                min-width: 200px;

                &:before,
                &:after {
                    display: none;
                }
                &:hover,
                &:focus {
                    background-color: rgb(255, 255, 255);
                }
            }

            .MuiSelect {
                &-root {
                }
                &-filled {
                    padding: 12px 42px 12px 10px;
                }
            }

            .MuiFilledInput-root {
            }
        }
    }

    .m-ganttSideBar {
        grid-area: ganttSideBar;
        height: 100%;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        background: white;
        font-weight: bold;
        color: hsl(var(--text-primary));
        -webkit-transition: all 200ms ease-in-out;
        -moz-transition: all 200ms ease-in-out;
        -ms-transition: all 200ms ease-in-out;
        -o-transition: all 200ms ease-in-out;
        transition: all 200ms ease-in-out;

        .-expand-icon {
            display: flex;
            justify-content: space-between;

            > div {
                height: inherit;
                display: flex;
                justify-content: center;
                flex-direction: column;
                padding: 0 10px;
                animation: fadein 1.5s ease forwards;

                > h3,
                h4 {
                    margin: 0 !important;
                    padding: 0 !important;
                    font-weight: 400;
                }
            }
        }

        &__itemsContainer {
            -webkit-transition: all 200ms ease-in-out;
            -moz-transition: all 200ms ease-in-out;
            -ms-transition: all 200ms ease-in-out;
            -o-transition: all 200ms ease-in-out;
            transition: all 200ms ease-in-out;
            opacity: 1;
        }

        &-open {
            @extend .m-ganttSideBar;
            width: 250px;
        }
        &-closed {
            @extend .m-ganttSideBar;
            width: 48px;

            .m-ganttSideBar__itemsContainer {
                opacity: 0;
            }
        }
        .sidebarRow-item {
            height: $row-height;
            background: rgba(0, 0, 0, 0.01);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            button {
                padding: 12px;
                height: 100%;
                width: 100%;
                text-align: left;
                color: hsl(var(--text-primary));
                &.disabled {
                    cursor: text;
                    user-select: text;
                }
            }

            &.-zero {
                padding: 10px;
                height: 88px;
                border-bottom: none;
                > div {
                    animation: fadein 1.5s ease forwards;
                }
            }
            &.-selected {
                // background: var(--gray-98);
                background: rgba(0, 0, 0, 0.05);
            }
        }
        .sidebarRow-item:hover {
            background: var(--gray-98);
        }
        .sidebarRow__header {
            padding: 12px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            height: $row-height;
            background: rgba(0, 0, 0, 0.25);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
    }

    .stickyHeader {
        height: 50px;
        position: sticky;
        background: white;
        top: 0;
        left: 0px;
        overflow: hidden;
        border-bottom: 1px solid #323a45;
        z-index: 1;
        font-size: 12px;
        font-weight: 600;

        &-closed {
            @extend .stickyHeader;
            box-shadow: none;
        }
        &-open {
            @extend .stickyHeader;
            padding: 3px;
        }
    }

    .body {
        grid-area: body;
        width: 100%;
        height: 100%;
    }
    display: grid;
    grid-template-areas: 'ganttSideBar body';
    grid-template-columns: auto 1fr;

    .hor-align-left {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 6px;
    }

    .-vertical-align {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.ganttHeaderMonths {
    background: #323a45;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: white;
    height: 100%;
    width: 100%;
    position: relative;
    .month {
        width: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.3);
        text-align: center;
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        white-space: nowrap;
        overflow: hidden;
    }
}

.ganttToday {
    &.verticalLine {
        fill: #323a45;
    }
    &.triangle {
        fill: white;
        stroke: black;
        stroke-width: 4;
    }
}

.ganttBarForeignObject {
    overflow: visible;
}

.render_timeline {
    height: 20px;
    width: 100%;
}

.gantt-relationship {
    stroke: rgba(0, 0, 0, 0.78);
    stroke-width: 2px;
}

.ganttSlider {
    grid-area: container;
    background: rgba(255, 255, 255, 0.75);
    box-sizing: border-box;

    #ganttHeaderMonths-container {
        overflow: hidden;
        pointer-events: none;
        top: 0px;
        position: relative;
        height: 25px;
    }

    .slider {
        margin: 0;
        position: relative;
        top: auto;
        left: 0;
        cursor: grab;
        height: 30px;
        width: 60px;
        background: lightblue;
        // border-radius: 10px;
        overflow: hidden;
        // direction: rtl;
    }
    .slider {
        width: 100%;
        -webkit-appearance: none;
        width: 100%;
        height: 20px;
        background: rgba(0, 0, 0, 0.125);
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        // direction: rtl;
    }

    .slider:hover {
        opacity: 1;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        transform: scale(var(--slider-width), 1);
        height: 25px;
        background: rgba(0, 0, 0, 0.78);
        cursor: grab;
        // border-radius: 10px;
    }

    .slider::-moz-range-thumb {
        width: 24px;
        height: 25px;
        transform: scale(var(--slider-width), 1);
        background: rgba(0, 0, 0, 0.78);
        cursor: grab;
        // border-radius: 10px;
    }

    .slider::-webkit-slider-thumb:active {
        cursor: grabbing;
    }

    .slider::-moz-range-thumb:active {
        cursor: grabbing;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
