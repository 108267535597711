.o-colorcircle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    transition: opacity 0.25s ease-in;
    &:hover {
        border: 1px solid black;
    }

    &:hover {
        opacity: 0.8;
    }
}
