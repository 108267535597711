.o-assesstech {
    &__headercontainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;

        button:first-child {
            margin-right: 10px;
        }
    }
}
