@import '~assets/sass/colors';
@import '~assets/sass/typography';

.o-introductionpage {
    width: 100%;
    height: calc(100vh - 65px);
    display: flex;
    padding: 170px 140px;

    p {
        text-align: left;
        line-height: 22px;
    }

    &__title {
        text-transform: uppercase;
        margin-left: -94px;

        div:first-child {
            color: var(--primary);
            font-size: 120px;
            font-weight: bold;
            display: inline-block;
            position: relative;
            overflow: hidden;
            line-height: 0.8;
        }
        div:last-child {
            color: var(--primary);
            font-weight: bold;
            font-size: 120px;
            position: relative;
            overflow: hidden;
            line-height: 0.9;
        }
        div::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: var(--primary);
            animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
            transform: translateX(-101%);
        }
        div::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #fafafa;
            animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
            transform: translateX(0);
        }
        div:first-child::before,
        div:first-child::after {
            animation-delay: 0.5s;
        }

        div:last-child::before,
        div:last-child::after {
            animation-delay: 1s;
        }
    }

    h1 {
        font-size: 30px;
        padding-bottom: 40px;
        margin-left: -87px;
        font-weight: normal;
        position: relative;
        overflow: hidden;
        animation-delay: 2.5s;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: var(--primary);
            animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
            transform: translateX(-101%);
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: #fafafa;
            animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) forwards;
            transform: translateX(0);
        }
    }

    button {
        margin-left: -2px;
        margin-top: 10px;
    }

    &__createTaskGroups {
        .o-taskGroupsshortcontainer {
            > div {
                opacity: 0;
                overflow: hidden;
                animation-duration: 1s;
                animation-timing-function: ease-out;
                animation-delay: 3s;
                animation: stretch 4s cubic-bezier(0.77, 0, 0.18, 1) forwards;
            }
        }
    }

    &__buildPlan {
        ul {
            list-style: none;
            margin-left: 0px;
            padding-left: 0px;
        }
        li {
            overflow: hidden;
            position: relative;
        }

        ul:first-child {
            li {
                display: inline-block;
                margin-right: 5px;
                background-color: var(--primary);
                border-radius: 50%;
                color: white;
                padding: 3px;
                font-weight: bold;
                width: 30px;
                text-align: center;
                unicode-bidi: bidi-override;
                direction: ltr;
                &:first-letter {
                    vertical-align: text-bottom;
                }
            }
        }

        ul:last-child {
            li {
                display: inline-block;
                margin-right: 15px;
                padding-left: 5px;
                border-left: 5px solid;
            }

            li:nth-child(1) {
                border-color: #ecbc8b;
            }
            li:nth-child(2) {
                border-color: #bbd4d9;
            }
            li:nth-child(3) {
                border-color: #69ffb5;
            }
        }

        li:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            background: var(--primary);
            animation: a-ltr-after 2s cubic-bezier(0.77, 0, 0.18, 1) 1s forwards;
            transform: translateX(-105%);
        }
        li:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 120%;
            height: 100%;
            background: #fafafa;
            animation: a-ltr-before 2s cubic-bezier(0.77, 0, 0.18, 1) 1s forwards;
            transform: translateX(0);
        }
    }
}

@keyframes a-ltr-after {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(101%);
    }
}

@keyframes a-ltr-before {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(200%);
    }
}

@keyframes stretch {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
