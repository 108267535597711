@import '~assets/sass/colors';

.a-updateTaskModal {
    &__dependenciesLabel {
        display: flex;
        font-weight: bold;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0 0 0 auto;
    }

    &__dependenciesList {
        margin: 0;
        list-style: none;

        &__item {
            margin: 1rem;
        }
    }

    .MuiAccordion {
        &-root {
            width: 100%;
        }
    }

    .MuiAccordionDetails {
        &-root {
            padding: 0;
        }
    }

    &__selectLabel {
        margin-bottom: 4px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--text-primary);
    }

    &__selectContainer {
        display: flex;
        flex-direction: column;
        .MuiInputBase {
            &-root {
                width: 50%;
            }
        }
    }

    .MuiAccordionSummary {
        &-root {
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;

            &.Mui-expanded {
                min-height: 56px;
                border-bottom: 1px solid get-color(--divider);
            }

            &.Mui-disabled {
                opacity: 1;
            }
        }

        &-content {
            display: grid;
            grid-template-columns: auto 1fr auto;
        }

        &-expandIcon {
            width: 65px;
            height: 65px;
            padding: 0px;
            align-self: flex-end;

            .MuiSvgIcon {
                &-root {
                    width: 70%;
                    height: 70%;
                    color: black;
                }
            }
        }
    }

    .MuiInputBase-input {
        border-radius: 4px;
        padding: 10px 26px 10px 12px;
        border: 1px solid #ced4da;

        &:focus {
            border-radius: 4px;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    .MuiTableCell {
        &-head {
            background-color: white;
            color: get-color(--warning-contrast-text);
            font-size: 14px;
            padding: 10px 10px 0 0;
            font-weight: var(--font-weight-semibold);
        }

        &-body {
            padding: 15px 0 15px 0px;
            font-size: 14px;
            &:first-child {
                padding-left: 0;
            }
        }
    }

    .MuiButtonBase {
        &-root {
            &.Mui-disabled {
                background-color: inherit;
            }
        }
    }

    &__dateInput {
        height: 40px;
        width: 65px;
        margin: 0 5px;
        padding-left: 12px;
    }

    .MuiAvatar-root {
        margin: 0;
    }

    .MuiTableRow {
        &-root {
            border-bottom: 1px solid #e0e0e0 !important;
            margin-bottom: 10px;
        }
    }

    .MuiPaper {
        &-root {
            border: none;
        }

        &-elevation1 {
            box-shadow: none;
        }
    }

    &__flexRow {
        display: flex;
        align-items: center;

        .MuiIcon {
            &-root {
                cursor: pointer;
                margin-right: 10px;
            }
        }
    }

    &.-noDependencies {
        .MuiAccordionSummary {
            &-root {
                &.Mui-expanded {
                    border-bottom: none;
                }
            }
            &-content {
                margin: 0;

                &.Mui-expanded {
                    margin: 0;
                }
            }
            &-expandIcon {
                transform: none;
            }
        }
    }
}
