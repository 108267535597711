@import '~assets/sass/colors';

.m-planFundamentals {
    width: 100%;

    &__stakeholder {
        margin-right: 3em;
    }

    &__stakeholderSection {
        display: flex;
    }

    &__section {
        padding: 12px;
    }

    &__subheading {
        font-size: 14px;
        font-weight: var(--font-weight-semibold);
        margin: 0 0 5px 0;
    }

    &__stakeholderContent {
        p {
            padding: 0;
            margin: 0;
        }

        &-bolder {
            font-weight: var(--font-weight-semibold);
        }
    }

    &__content {
        margin: 0 0 15px 0;
    }

    &__content:nth-child(2) {
        margin: 0;
    }

    &__readinessContent {
        margin: 15px;
    }

    &__subSection {
        padding: 5px 0px 5px 15px;
        background-color: get-color(--divider);
        p {
            margin: 0;
            color: get-color(--black-hsl);
            font-weight: var(--font-weight-bold);
        }
    }

    &__separator {
        border-bottom: 1px solid get-color(--divider);
    }

    .-sectionDivider {
        padding-left: 12px;
        border-left: 1px solid get-color(--divider);
    }
}
