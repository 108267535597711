@import '~assets/sass/colors';

.o-taskform {
    margin-bottom: 170px;

    &__hr {
        width: calc(100% + 30px);
        border-top: 1px solid get-color(--divider);
        border-bottom: none;
        margin-left: -15px;
    }

    form {
        > * {
            margin-bottom: 15px;
        }
    }
    &__statusContainer {
        display: flex;
        align-items: center;

        p {
            margin: 0px;
        }
    }

    &__selectLabel {
        .MuiInputLabel-outlined,
        .MuiInputLabel-shrink {
            transform: translate(0, -20px) scale(1) !important;
        }
    }

    &__description {
        textarea {
            padding: 8px 10px;
        }
    }

    &__readOnlyLabel {
        color: get-color(--text-primary);
        font-weight: 600;
        margin-bottom: 14px;
    }

    &__readOnlyContent {
        color: get-color(--text-secondary);
        margin: 0;

        .-dollarSign {
            color: get-color(--text-primary);
        }
    }

    &__readOnlyContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &__taskMenu {
        position: relative;
        right: 35px;
        top: -10px;
        float: right;
        cursor: pointer;
        .MuiIcon-root {
            color: get-color(--error-main);
        }
        &:hover {
            .MuiIcon-root {
                color: get-color(--error-dark);
            }
        }
    }

    &__taskGroupColor {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 1.2em;
        width: 1.2em;
        margin-right: 10px;
    }

    &__amount {
        text-align: center;
        display: flex;
        align-items: center;

        input {
            text-align: left;
            padding: 11px 14px;
        }

        span:first-child {
            font-weight: bold;
            float: left;
            padding-right: 5px;
        }
    }

    &__taskGroupOption {
        display: flex;
    }

    &__buttons {
        border-top: 1px solid get-color(--divider);
        position: fixed;
        width: 385px;
        bottom: 0px;
        right: 15px;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        background-color: white;
        padding: 0px 15px 20px 0px;
        margin-bottom: 0px !important;

        button {
            margin: 10px 0 0 10px;
        }

        .-textDisabled {
            .MuiButtonBase-root.Mui-disabled {
                background-color: transparent;
            }
        }
    }

    .o-randomcolorcontainer {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 75px;
        right: 15px;
    }

    form {
        margin-top: 30px;
    }
    .m-adddependencies {
        list-style: none;
        margin: 4px 0 0 0;
        padding: 0px;
        border-radius: 6px;
        box-shadow: 0px 1px 2px #0000004a;
        position: absolute;
        background-color: white;
        width: 92.5%;
        z-index: 999;
        overflow: hidden;

        &.hidden {
            display: none;
        }

        &__name {
            flex-grow: 5;
            text-align: left;
        }

        &__header {
            font-size: 14px;
            color: var(--deep-blue);
            font-weight: var(--font-weight-semibold);
            margin-bottom: 2px;
            display: block;
        }

        &__buttons {
            display: flex;
            justify-content: flex-end;

            button:first-child {
                margin-right: 10px;
            }
        }

        &__itemcontainer {
            height: 43px;
            color: var(--soft-black);
            cursor: pointer;
            &:hover {
                font-weight: var(--font-weight-semibold);
                background-color: var(--light-blue-gray);
            }
        }

        &__itembutton {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            height: 100%;
            align-items: center;
            padding: 5px 10px;
        }

        &__selected-container {
            color: var(--soft-black);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 3px 5px 3px 10px;
            background-color: var(--light-blue-gray);
            border: 1px solid #667989;
            border-radius: 3px;
            margin-bottom: 10px;

            .profile_picture_icon {
                padding-right: 0px;
            }
        }
    }

    &.readonly {
        h2 {
            font-weight: 600;
            width: 80%;
        }

        form {
            margin-top: 15px;
            padding-left: 6px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            p {
                width: 100%;
            }

            > div {
                width: 100%;
            }

            .o-taskform__buttons {
                width: 385px;
            }
        }

        .o-dependencies {
            margin-top: 5px;
        }
    }

    &__dates {
        display: grid;
        grid-template-columns: 1fr 1fr;

        section {
            .MuiFormControl-root {
                max-width: 170px;
            }
        }
    }

    &__datesTitle {
        font-size: 18px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--warning-contrast-text);
        margin: 0 0 10px 0;
    }

    .o-taskform__readOnlyContainer {
        .o-taskform__dateContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
    }

    .o-taskform__dateContainer {
        .DoubleDatePicker__inputsContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            .DoubleDatePicker__duration {
                grid-column-start: 1;
                grid-column-end: 3;
                margin-top: 8px;
            }
        }

        .MuiInputLabel {
            &-root {
                text-align: left;
            }
        }

        .MuiFormLabel-root,
        .label {
            font-size: 16px;
            color: get-color(--warning-contrast-text);
            font-weight: 600;
        }

        .value {
            margin: 1rem 0;
        }
    }

    &__duration {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        .days {
            margin-left: 5px;
        }
    }
}

.o-offcanvas.--closed {
    .o-taskform__buttons {
        display: none;
    }
}

.o-randomcolorcontainer__tooltip-content {
    width: auto;
    border-color: #cdcdcd;
    box-shadow: 0px 1px 1px #48484829;
    ul {
        padding: 0px 10px;
        display: grid;
        grid-gap: 10px 10px;
        grid-template-columns: repeat(3, 20px);
        grid-template-rows: repeat(2, 20px);
    }
}

.o-randomcolorcontainer__tooltip {
    &-arrow {
        display: block;
        margin-top: 2px;
        margin-right: 4px;
        stroke-width: 4px;
        stroke: #cdcdcd;
    }
}

.o-dependencies {
    &__tasklist {
        list-style: none;
        margin: 0px 0px 10px 0px;
        padding: 0px;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid var(--medium-blue-grey);
            padding: 5px 0;
        }
    }
}
