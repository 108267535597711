@mixin disable-mouse-outline {
    [data-whatinput='mouse'] & {
        outline: 0;
    }
}

html {
    box-sizing: border-box;
    font-size: var(--font-size-normal);
}

// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
    box-sizing: inherit;
}

// Default body styles
body {
    margin: 0;
    padding: 0;

    background-color: var(--background) !important;

    font-family: $montserrat;
    font-weight: var(--global-weight);
    line-height: var(--global-lineheight);
    color: var(--text);
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
}

img {
    // Get rid of gap under images by making them display: inline-block; by default
    display: inline-block;
    vertical-align: middle;

    // Grid defaults to get images and embeds to work properly
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
}

// Make sure textarea takes on height automatically
textarea {
    height: auto;
    min-height: 50px;
    border-radius: var(--global-radius);
}

// Make select elements are 100% width by default
select {
    box-sizing: border-box;
    width: 100%;
    border-radius: var(--global-radius);
}

// Styles Google Maps and MapQuest embeds properly
// sass-lint:disable-line no-ids
.map_canvas,
.mqa-display {
    img,
    embed,
    object {
        max-width: none !important;
    }
}

// Reset <button> styles created by most browsers
button {
    @include disable-mouse-outline;
    padding: 0;
    appearance: none;
    border: 0;
    border-radius: var(--global-radius);
    background: transparent;
    line-height: 1;
    cursor: pointer;
}

// Prevent text overflow on pre
pre {
    overflow: auto;
}

// Make reset inherit font-family instead of settings sans-serif
button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
}

// Internal classes to show/hide elements in JavaScript
.is-visible {
    display: block !important;
}

.is-hidden {
    display: none !important;
}

// Added this to override uikit styling - remove once uikit is removed
a {
    border-bottom: none !important;
}

.--noPadding {
    padding: 0 !important;
}

.--noMargin {
    margin: 0 !important;
}
