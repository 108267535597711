.Gantt {
    &.zero {
        overflow-y: hidden;
    }
    .body {
        &_canvas {
            &__container {
                cursor: grab;
                overflow: hidden;

                &_ganttCanvas {
                    overflow: hidden;
                    overflow-x: scroll;
                    &_zero {
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
            &__container:active {
                cursor: grabbing;
            }
        }
    }
}
