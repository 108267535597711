@import '~assets/sass/colors';

.o-resourceList {
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid get-color(--divider);

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: get-color(--divider);
        padding: 14px 18px;
        font-weight: bold;
        font-size: 16px;
    }

    &__footer {
        background-color: #323a45;
    }

    &__tableRow {
        border-top: 1px solid get-color(--divider);

        &.-total {
            border-top: 1px solid get-color(--black-hsl);
        }
    }

    &__tableText {
        font-size: 16px;
    }

    &__resourceName {
        font-weight: bold;
        font-size: 16px;
    }

    &__resourceTotal {
        text-align: right;
        font-size: 16px;

        &.-project {
            font-weight: bold;
            color: get-color(--white-hasl);
        }
    }

    &__resourceUser {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 16px;
    }

    &__resourceAmount {
        display: flex;
        justify-content: space-between;
        font-size: 16px;
    }

    .MuiTableCell-body:first-child {
        padding-left: 16px;
    }

    .-whiteText {
        color: get-color(--white-hsl);
    }
}
