.ttbody {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
    text-align: left;
    padding: 4px;

    &.wide {
        @extend .ttbody;
        min-width: 260px;
    }

    h4 {
        margin-bottom: 4px;
    }

    .space-between {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .datePill {
            background: white;
            color: rgba(0, 0, 0, 0.78);
            border-radius: 20px;
            padding: 4px 12px;
            margin-left: 10px;
        }
    }
}
