@import '~assets/sass/colors';

.o-FormButtons {
    border-top: 1px solid get-color(--divider);
    position: fixed;
    width: 380px !important;
    bottom: 0px;
    right: 15px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    background-color: white;
    padding: 0px 15px 20px 0px;
    margin-bottom: 0px !important;

    button {
        margin: 10px 0 0 10px;
    }

    .-textDisabled {
        .MuiButtonBase-root.Mui-disabled {
            background-color: transparent;
        }
    }
}

.o-offcanvas.--closed {
    .o-FormButtons {
        display: none;
    }
}
