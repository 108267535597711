@import '~assets/sass/colors';
.o-listView {
    margin: 25px 0px 0px 0px;

    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
    }
    &__taskGroupsContainer {
        margin-top: 20px;

        > div {
            margin-bottom: 20px;
        }

        > div:last-child {
            margin-bottom: 0px;
        }
    }

    &__emptyData {
        margin: 20px 0px;
        &.-header {
            color: get-color(--text-primary);
            margin: 0;
            font-size: var(--font-size-xlarge);
            font-weight: 600;
        }
        &.-message {
            color: get-color(--text-secondary);
            font-size: var(--font-size-normal);
            margin: 5px 0px 20px 0px;
        }
    }

    &__bulkActions {
        .MuiAutocomplete-root {
            width: 250px !important;
        }

        .MuiInputBase-root {
            padding-right: 38px !important;
            color: get-color(--text-primary);
            background-color: get-color(--divider);
            input {
                border-right: 1px solid get-color(--text-primary);
            }
        }

        .MuiSvgIcon-root {
            color: get-color(--text-primary);
        }

        &-active {
            .MuiAutocomplete-root {
                width: 250px !important;
            }

            .MuiInputBase-root {
                padding-right: 38px !important;
                color: get-color(--white-hsl);
                background-color: get-color(--secondary-main);
                input {
                    border-right: 1px solid get-color(--divider);
                }

                &:hover {
                    background-color: get-color(--secondary-dark);
                }

                &:active {
                    background-color: get-color(--secondary-light);
                }
            }

            .MuiSvgIcon-root {
                color: get-color(--white-hsl);
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border: none !important;
                border-width: 0 !important;
            }
        }
    }
}
