@import '~assets/sass/colors';

$milestone-modal-length: 450px;

.my-popup-content {
    margin: auto;
    background: transparent !important;
    width: 50%;
    padding: 5px;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;

    &__milestoneOption-content {
        width: $milestone-modal-length;
    }
}

.my-popup-arrow {
    color: transparent !important;
    path {
        stroke: none !important;
    }
}

.DoubleDatePicker {
    justify-content: flex-start;
    flex-direction: column;
    box-shadow: 3px 3px 8px get-color(--black-025-hsl);
    background-color: get-color(--white-hsl);
    border: 1px solid get-color(--black-025-hsl);
    border-radius: 8px;
    padding: 20px;
    display: inline-block;

    &__container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__popContainer {
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        margin-top: 20px;

        button:first-child {
            margin-right: 20px;
        }
    }

    .MuiPickersCalendar-daysHeader {
        .MuiPickersCalendar-weekDayLabel:nth-child(1) {
            &::after {
                content: 'u';
                display: inline-block;
            }
        }
        .MuiPickersCalendar-weekDayLabel:nth-child(2) {
            &::after {
                content: 'o';
                display: inline-block;
            }
        }
        .MuiPickersCalendar-weekDayLabel:nth-child(3) {
            &::after {
                content: 'u';
                display: inline-block;
            }
        }
        .MuiPickersCalendar-weekDayLabel:nth-child(4) {
            &::after {
                content: 'e';
                display: inline-block;
            }
        }
        .MuiPickersCalendar-weekDayLabel:nth-child(5) {
            &::after {
                content: 'h';
                display: inline-block;
            }
        }
        .MuiPickersCalendar-weekDayLabel:nth-child(6) {
            &::after {
                content: 'r';
                display: inline-block;
            }
        }
        .MuiPickersCalendar-weekDayLabel:nth-child(7) {
            &::after {
                content: 'a';
                display: inline-block;
            }
        }
    }

    .MuiPickersDay-root.Mui-selected {
        border-radius: 50%;
    }

    &__milestoneOption {
        width: $milestone-modal-length;
        margin: auto;

        .MuiPickersDay-root.Mui-selected {
            border-radius: 0;
            transform: rotate(52deg) skew(15deg);
            width: 20px;
            height: 20px;
            margin: 8px 10px;

            .MuiPickersDay-dayLabel {
                transform: rotate(-45deg) skew(0deg);
            }
        }
    }

    &__header {
        padding: 5px 0px 15px 0px;
        border-bottom: 1px solid get-color(--divider);
    }

    &__calendar {
        border-bottom: 1px solid get-color(--divider);

        .MuiSvgIcon-root {
            color: #396ace;
        }
    }

    &__body {
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
    }

    &__inputsContainer {
        padding: 10px 0;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr 1fr;

        .MuiInputAdornment-positionEnd {
            display: None;
        }

        input,
        label {
            font-weight: 600;
            font: 16px/20px Montserrat;
            font-size: 16px;
            color: get-color(--warning-contrast-text);
            text-align: left;
            &:focus::placeholder,
            &::placeholder {
                font-weight: 600 !important;
                font: 16px/20px Montserrat !important;
                font-size: 16px;
                color: get-color(--warning-contrast-text) !important;
                text-align: left;
                opacity: 1 !important;
                margin: 0 !important;
                padding: 0 !important;
                display: inline-block !important;
            }
        }

        button {
            display: grid;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            text-align: left;
        }

        .MuiFormHelperText {
            &-root {
                display: none;
            }
        }

        .MuiInput {
            &-root {
                input {
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    border-radius: 4px;
                    font-size: 16px;

                    &:hover {
                        border-color: get-color(--primary-main);
                    }

                    &:focus {
                        border-color: get-color(--primary-main);
                    }
                }
            }

            &-underline {
                &:before,
                &:after {
                    display: none;
                }
            }
        }

        .MuiFormLabel {
            &-root {
                line-height: 14px;
            }
        }

        .MuiInputLabel {
            &-root {
                padding: 0;
            }

            &-formControl {
                transform: none;
                position: relative;
                margin-bottom: 1rem;
            }
        }

        label + .MuiInput-formControl {
            margin: 0;
        }
    }

    &__duration {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: row;

        input,
        label {
            font: normal normal medium 16px/20px Montserrat !important;
            font-size: 16px;
            color: get-color(--warning-contrast-text);
            text-align: left;
        }

        &__businessDays {
            display: flex;
            margin-left: 10px;
            color: get-color(--black-050-hsl);
            justify-content: flex-start;
            flex-direction: column-reverse;
        }

        .MuiInputBase {
            &-input {
                width: 74px;
            }
        }
    }

    &__setDateButton {
        display: flex;
    }
}
