@import '~assets/sass/colors';

.o-resourceCard {
    min-width: 286px;
    display: flex;
    flex-direction: column;
    flex-basis: 286px;
    align-items: flex-start;
    padding: 1rem;
    border: 1px solid get-color(--divider);
    border-radius: 0.5em;

    &__resourceType {
        margin-bottom: 10px;
    }

    &__resourceAssignee {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .a-userAvatar {
            margin-right: 5px;
        }
    }
}
