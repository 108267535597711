.content {
    padding: 0 28px;

    @media (min-width: 1280px) {
        padding: 0 38px;
    }

    @media (min-width: 1920px) {
        padding: 0 57px;
    }
}

.subheader {
    padding: 30px 0 0 0;

    @media (min-width: 1280px) {
        padding: 40px 0 0 0;
    }

    @media (min-width: 1920px) {
        padding: 60px 0 0 0;
    }
}
