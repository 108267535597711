@import '~assets/sass/colors';

.o-activityCard {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;

    &__item {
        display: grid;
        column-gap: 0.5rem;
        grid-template-columns: 70% 30%;
        grid-template-rows: auto;
        grid-template-areas:
            'heading  cost'
            'users cost'
            'desc .';
        padding: 1.25rem 1rem;
        width: 100%;
    }

    &__item:not(:last-child) {
        border-bottom: 1px solid get-color(--divider);
    }

    &__emptyTaskGroups {
        padding: 4px 0px 4px 15px;
    }

    &__heading {
        display: flex;
        grid-area: heading;
        align-items: center;
        margin-bottom: 6px;
    }

    &__name {
        margin: 0;
        font-size: 16px;
        font-weight: var(--font-weight-semibold);
    }

    &__costContainer {
        grid-area: cost;
        justify-self: center;
    }

    &__costTitle {
        font-weight: var(--font-weight-semibold);
        margin: 0 0 0.75rem 0;
    }

    &__costAmount {
        margin: 0;
        text-align: right;
    }

    &__usersContainer {
        grid-area: users;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;

        .MuiIcon-root {
            color: get-color(--text-secondary);
        }
    }

    &__color {
        border-radius: 50%;
        display: inline-block;
        height: 1.25rem;
        margin-right: 0.5rem;
        width: 1.25rem;
    }

    &__users {
        display: inline;
        margin: 0;
    }

    &__desc {
        grid-area: desc;
        margin: 0;
    }
}
