@import 'assets/sass/index';

.App {
}

.screen-initialization {
    height: 100vh;
    width: 100%;
    background: #353535;
    color: rgba(255, 255, 255, 0.78);
    padding: 10px;
}
