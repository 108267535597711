.o-align-resource-expandable-row {
    .fundingTable {
        &__container {
            margin: 0 30px;
        }
    }

    .a-graph {
        &__container {
            display: flex;
            justify-content: space-between;
            margin: 0 14px;

            > div {
                width: 100%;
                position: relative;
                .a-graph-header-offset {
                    margin-left: -12px;
                }
                .a-graph-guts {
                    height: 74px;
                    &__bars {
                        top: 54%;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }

        .summaryTable {
            min-width: 370px;
        }
    }
}
