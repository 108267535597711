@import '~assets/sass/colors';

.o-resourceBreakdownTable {
    color: get-color(--primary-main);
    width: 100%;

    .a-table {
        &__row {
            background: get-color(--light-gray);
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            border: 1px solid get-color(--divider);
            min-height: 42px;
            border-radius: 4px;

            &:last-child {
                margin-bottom: 15px;
            }

            .a-funding {
                max-width: 150px;
            }

            .-fullWidth {
                width: 100%;
            }

            &.-header {
                border-radius: 4px 4px 0 0;
                display: flex;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: none;
            }

            &.-body {
                border-radius: 0 0 4px 4px;
                margin-bottom: 10px;
            }

            .a-cell {
                border-bottom: 1px solid get-color(--gray-350);
                border-left: 1px solid get-color(--gray-350);
                width: 100%;
                min-height: 42px;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;

                &__empty {
                    @extend .a-cell;
                    padding-left: 10px;
                }

                &.-pd {
                    padding: 4px 0 4px 10px;
                }

                &.-initial {
                    border-left: none;
                }

                &__terminus {
                    @extend .a-cell;
                    max-width: 120px;
                    min-width: 120px;
                }

                &.-length120 {
                    min-width: 120px;
                    max-width: 120px;
                }

                &.-overAllocated {
                    background-color: get-color(--error-background);
                    color: get-color(--error-dark);
                }

                &__overAllocatedSpan {
                    font-size: 12px;
                }

                &__chartBackdrop {
                    position: relative;
                    padding-left: 10px;
                    height: 100%;
                    width: inherit;
                }

                &__chartBackdrop__container {
                    top: 22%;
                    position: absolute;
                    width: 100%;
                }

                &__dollarFormat {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    font-weight: bold;
                }

                &__dollarAmount {
                    text-align: right;
                    min-width: 100px;
                    padding-right: 6px;
                }
            }
        }
    }
}

.fc-fs {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start !important;
}

.fc-c {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
}

.fr-c {
    display: flex !important;
    justify-content: center !important;
}

.fr-fs {
    display: flex !important;
    justify-content: flex-start !important;
}

.fc-sb {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between !important;
}

.fr-sb {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
}
