.m-customFundingChip {
    display: flex;
    align-items: center;
    justify-content: center;

    &__badge {
        margin: 0 1em;
        cursor: pointer;
    }

    &__badgeList {
        list-style: none;
        margin: 0px;
        padding: 0px;
        font-size: 12px;
    }

    &__hoverAmount {
        margin-left: 25px;
    }

    &__hoverItemContainer {
        margin: 4px 0px;
    }

    &__hoverResources {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}
