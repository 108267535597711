.uikit_search_container {
    .uikit_search_container__input-wrapper + .uikit_search_container__button {
        margin-left: 0;
        .uikit_button {
            border-radius: 0 5px 5px 0;
            background-color: var(--white);
            color: var(--gray-46);
            border: 2px solid var(--gray-46);
        }
    }
}

.uikit_Icon {
    margin: 0 0px;
}

.uikit_Icon.primary.m-searchList__itemAvatar {
    &:hover {
        background-color: transparent;
    }
}

.uikit_button {
    transition: all 0.1s ease-in;
}

.uikit_button.primary.button-gray {
    background-color: #d8dee2;
    color: #000000;
    transition: all 0.1s ease-in;

    &:hover {
        color: white;
        background-color: #0e788b;
    }
}

.uikit_select_container {
    height: auto !important;
}
