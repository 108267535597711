@import '~assets/sass/colors';

.o-planbuildsidebar {
    background-color: get-color(--white-hsl);
    border-right: 3px solid get-color(--divider);
    min-height: calc(100vh - 65px);

    &__drawerContainer {
        display: flex;
        position: sticky;
        overflow: hidden;
        top: 0;
    }
    &__drawerPaper {
        &.-drawerOpen {
            .MuiDrawer-paper {
                width: 250px;
                position: inherit;
                transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            }
        }
        .MuiDrawer-paper {
            width: 40px;
            position: inherit;
            overflow-x: hidden;
            transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
        }
    }

    &__container {
        &.-drawerOpening {
            animation: fadeIn 20s;
        }
    }

    &__planDetailsContainer {
        display: flex;
        flex-direction: column;
        padding: 20px 15px;
    }

    &__navItemsContainer {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
    }

    &__navItemsSectionTitle {
        display: flex;
        align-items: center;
        color: get-color(--primary-light);
        font-size: var(--font-size-large);
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 5px;
        .MuiIconButton-root {
            &:hover {
                background-color: transparent;
                color: get-color(--secondary-dark);
            }
            color: get-color(--secondary-main);
            padding: 0px;
            margin-left: 10px;
        }
    }

    &__navItem {
        cursor: pointer;
        border-radius: 4px;
        margin: 10px 0px;
        a {
            width: 100%;
            color: get-color(--text-primary);
            margin: 5px 0px;
        }
        &.-selected {
            background-color: get-color(--gray-300);
            a {
                font-weight: bold;
            }
        }
        &:hover {
            border-radius: 4px;
            background-color: get-color(--gray-200);
            a {
                font-weight: bold;
                color: get-color(--text-primary);
            }
            &.-selected {
                background-color: get-color(--gray-300);
            }
        }
    }

    &__planName {
        font-weight: 600;
        margin-bottom: 5px;
    }

    &__iconContainer {
        background-color: get-color(--secondary-main);
        &:hover {
            background-color: get-color(--secondary-dark);
        }
        height: 30px;
        width: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        .MuiIconButton-root {
            &:hover {
                background-color: transparent;
            }
            color: get-color(--white-hsl);
        }
    }

    &__toggleOpenIconContainer {
        height: 66px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.-drawerOpen {
            margin-right: 10px;
            justify-content: flex-end;
        }
    }

    .MuiPaper-root {
        border: none;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
