.o-timelineScaleScrollbar {
    background-color: white;
    display: flex;
    align-items: flex-start;
    height: 24px;
    cursor: pointer;

    .scrollhost-container {
        position: relative;
        height: 100%;
        width: 100%;
    }

    &__scrollHost {
        overflow: auto;
        height: 100%;
        width: 100%;
        scrollbar-width: none;
        -ms-overflow-style: none;
        position: relative;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__scrollBar {
        width: 20px;
        height: 100%;
        right: 0;
        top: 0px;
        position: absolute;
        border-radius: 7px;
        bottom: 0px;
        background-color: white;
    }

    &__scrollThumbContainer {
        height: 25px;
        margin-left: 0px;
        position: absolute;
        top: 0px;
    }

    &__scrollThumb {
        height: 24px;
        width: calc(100% - 20px);
        border-radius: 0px;
        opacity: 0.22;
        background-color: #1b95de;
        cursor: grab;
    }

    &__scrollThumb:active {
        opacity: 0.3;
        cursor: grabbing;
    }

    &__scrollThumbHandleLeft {
        height: 24px;
        width: 10px;
        background-color: #1b95de;
        border-radius: 5px 0 0 5px;
        &:hover {
            cursor: col-resize;
        }
    }

    &__scrollThumbHandleRight {
        height: 24px;
        width: 10px;
        background-color: #1b95de;
        border-radius: 0px 5px 5px 0px;
        &:hover {
            cursor: col-resize;
        }
    }
}
