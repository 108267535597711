@import '~assets/sass/colors';
.o-addRowButton {
    border: 1px solid get-color(--divider);
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;

    .o-addRowButton__text {
        font-size: var(--font-size-normal);
    }
    button {
        width: 100%;
    }

    .-alignLeft {
        display: flex;
        justify-content: flex-start;
    }

    .MuiIcon-root {
        font-size: 24px;
    }

    .MuiButton-root {
        background-color: get-color(--white-hsl);
        color: get-color(--secondary-main);
        border-radius: inherit;
        height: 40px;
        &:hover {
            color: get-color(--white-hsl);
            background-color: get-color(--secondary-main);
        }
    }
}
