@import '~assets/sass/colors';

.o-dependencies {
    margin: 0px;

    &__header {
        display: flex;
    }

    &__icon {
        margin-right: 8px;
    }

    &__title {
        font-size: 18px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--warning-contrast-text);
        margin: 0 0 10px 0;
    }

    &__closeIcon {
        cursor: pointer;
        margin-right: 5px;
    }

    &__tasklist {
        p {
            color: get-color(--text-secondary);
            font-size: 12px;
        }
    }

    &__parent-title,
    &__child-title {
        margin: 8px 0 0 0;
        font-size: 16px;
        color: get-color(--warning-contrast-text);
        font-weight: 600;
    }

    &__taskListContainer {
        padding: 10px;
    }

    &.readonly {
        .o-dependencies {
            &__header {
                display: flex;
                justify-content: flex-start;
                flex-wrap: nowrap;
                align-items: center;
                border-bottom: 0px;

                button:last-child {
                    margin-top: 1px;
                    margin-left: -5px;
                    align-self: center;
                    font-size: 15px;
                }
            }

            &__tasklist {
                .MuiButton-text {
                    padding: 0px;
                    text-align: left;
                    font-size: 14px;
                    color: get-color(--text-secondary);
                    &:hover {
                        text-decoration: underline;
                    }
                }

                .MuiButton-root {
                    min-width: 0;
                }

                li {
                    padding: 0;
                    margin-bottom: 6px;
                }
            }
        }
    }
}
