@keyframes anvil {
    0% {
        transform: scale(1) translateY(0px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    1% {
        transform: scale(0.96) translateY(10px);
        opacity: 0;
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
    }
    100% {
        transform: scale(1) translateY(0px);
        opacity: 1;
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
    }
}

.o-tooltipMenu {
    &__icon {
        cursor: pointer;
        padding: 3px 0px;
        &:hover {
            opacity: 0.5;
        }
    }

    &-content {
        border: 0xp;
        box-shadow: 0px 3px 3px #00000061 !important;
        border-radius: 4px;
        padding: 0px;
        animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
        font-size: 16px/24px;
        overflow: hidden;

        ul {
            padding: 0px;
            margin: 0 4px 0 0;
            width: 8rem;
        }
        li {
            list-style: none;
            width: 100%;
            cursor: pointer;

            button {
                font-size: 16px;
                font-family: 'Montserrat', sans-serif;
                text-align: left;
                padding: 15px;
                width: 100%;
            }

            &:hover {
                background: #d8dee2 0% 0% no-repeat padding-box;
            }
        }

        li:first-child {
            margin-top: 0px;
        }

        li:last-child {
            margin-bottom: 0px;
        }

        li.alert {
            font-size: 16px;
            font-family: 'Montserrat', sans-serif;
            text-align: left;
        }
    }

    &-arrow {
        display: none;
    }
}
