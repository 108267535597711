@import '~assets/sass/colors';

.o-resourceForm {
    margin-bottom: 170px;

    &__resource-menu {
        position: relative;
        right: 35px;
        top: -10px;
        float: right;
        cursor: pointer;
        .MuiIcon-root {
            color: get-color(--error-main);
        }
        &:hover {
            .MuiIcon-root {
                color: get-color(--error-dark);
            }
        }
    }

    &__hr {
        width: calc(100% + 30px);
        border-top: 1px solid get-color(--divider);
        border-bottom: none;
        margin-left: -15px;
    }

    form {
        > * {
            margin-bottom: 15px;
        }
    }

    &__statusContainer {
        display: flex;
        align-items: center;

        p {
            margin: 0px;
        }
    }

    &__selectLabel {
        .MuiInputLabel-outlined,
        .MuiInputLabel-shrink {
            transform: translate(0, -20px) scale(1) !important;
        }
    }

    &__description {
        textarea {
            padding: 8px 10px;
        }
    }

    &__readOnlyLabel {
        color: get-color(--text-primary);
        font-weight: 600;
        margin-bottom: 14px;
    }

    &__readOnlyContent {
        color: get-color(--text-secondary);
        margin: 0;
    }

    &__readOnlyContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &__resourceOption {
        display: flex;
    }

    form {
        margin-top: 30px;
    }

    &.readonly {
        h2 {
            font-weight: 600;
            width: 80%;
        }

        form {
            margin-top: 15px;
            padding-left: 6px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            p {
                width: 100%;
            }

            > div {
                width: 100%;
            }
        }
    }

    &__dates {
        display: flex;
        flex-direction: column;

        section {
            .MuiFormControl-root {
                max-width: 170px;
            }
        }
    }

    &__sectionTitle {
        font-size: 18px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--warning-contrast-text);
        margin: 0 0 10px 0;
    }

    &__dateContainer {
        .o-taskform__dateContainer .DoubleDatePicker__inputsContainer {
            padding: 0;

            button {
                display: flex;
                flex-direction: column;
                grid-gap: 20px;
            }
        }

        .o-dates__dateContainer {
            display: flex;

            .DoubleDatePicker__inputsContainer {
                display: flex;
                flex-direction: column;
            }
        }

        .o-dates__readOnlyContainer {
            .o-dates__dateContainer {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }

        .MuiInputLabel {
            &-root {
                text-align: left;
            }
        }

        .MuiFormLabel-root,
        .label {
            font-size: 16px;
            color: get-color(--warning-contrast-text);
            font-weight: 600;
        }

        .value {
            margin: 1rem 0;
        }
    }

    &__duration {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;

        .days {
            margin-left: 5px;
        }
    }
}
