.o-customSelect__multi {
    .MuiInputBase-input {
        background-color: hsl(var(--paper));
        border: 1px solid #ced4da;
        border-radius: 4;
        padding: 10px 26px 10px 12px;
        position: relative;
        transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

        &:focus {
            border-color: #80bdff;
            border-radius: 4;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }
}
