@import '~assets/sass/colors';

.o-header {
    padding: 0px 15px;
    background-color: get-color(--primary-main);
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    column-gap: 10px;
    justify-items: end;
    align-items: center;
    height: 65px;

    > div:first-child {
        justify-self: start;
    }

    > div:last-child {
        justify-self: end;
        width: 100%;

        @media (max-width: 2080px) {
            width: 100%;
        }
        @media (max-width: 1590px) {
            width: 100%;
        }
    }
}
