.avatar {
    top: 0;
    right: -40px;
    max-width: 28px;
    max-height: 28px;
    min-width: 28px;
    min-height: 28px;
    border-radius: 30px;
    box-sizing: border-box;
    background: #e8e8e8;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.5);
    &.absolute {
        @extend .avatar;
        position: absolute;
    }
    &.absolute:hover {
        border: 2px solid rgba(0, 0, 0, 0.5);
    }
}
