@import '~assets/sass/colors';

.m-identifyResourcesIntroduction {
    flex-grow: 1;

    .MuiGrid-item {
        justify-content: start;
        max-width: 508px;
    }

    &__button {
        margin-top: 12px !important;
    }

    &__head {
        font-size: 84px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 0.81;
        color: get-color(--primary-dark);
    }

    &__headSmall {
        font-size: 29px;
        text-transform: uppercase;
        font-weight: bold;
        color: get-color(--primary-dark);
    }

    &__headSub {
        padding-top: 20px;
        font-size: 20px;
        font-weight: bold;
    }

    &__rightColumn {
        padding-left: 1.5em;
    }

    &__chart {
        p {
            margin: -1.5em 0 0 4em;
        }
    }

    &__chartTitle {
        margin: 9em 0 0 4em;

        &-head {
            font-size: 18px;
            padding-bottom: 8px;
        }

        &-amount {
            font-size: 24px;
            font-weight: bold;
            padding-bottom: 32px;
        }
    }

    &__rightColumn {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}
