@import '~assets/sass/colors';

.o-customSelect {
    margin-top: 35px;
    &__inputLabel {
        .MuiInputLabel-outlined,
        .MuiInputLabel-shrink {
            transform: translate(0, -20px) scale(1) !important;
        }
        .MuiInputLabel-formControl {
            color: get-color(--text-primary);
        }
    }
    .MuiFormLabel-root {
        font-size: 16px;
        color: get-color(--text-primary);
    }

    .MuiFormHelperText-root {
        margin: 0px;
        color: get-color(--text-secondary);
        font-size: 14px;
    }
}
