@import '~assets/sass/colors';

.o-customInput {
    &.-displayOnly {
        > div > div {
            > input {
                padding: 0;
            }
            > fieldset {
                border: none;
            }
        }

        .Mui-disabled {
            color: get-color(--text-secondary);
        }
    }

    &__inputLabel {
        .MuiInputLabel-formControl {
            position: static;
            transform: none;
            color: get-color(--text-primary);
        }
    }

    &__amount {
        display: flex;
        align-items: baseline;

        span {
            padding-right: 5px;
            font-weight: 600;
        }

        .MuiOutlinedInput-input {
            padding: 9px 14px;
        }
    }

    &__inputContainer {
        display: flex;
        align-items: center;
    }

    .MuiFormLabel-root {
        font-size: 16px;
        color: get-color(--text-primary);
    }

    &.-notTextbox {
        .MuiOutlinedInput-root {
            height: 40px;
        }
    }

    &.-inputSpacing {
        .MuiInputBase-root {
            margin-top: 30px;
        }
    }

    &__characterCount {
        margin: 8px 0 0 0;
        font-size: var(--font-size-tiny);
        color: get-color(--text-secondary);
        text-align: right;

        &.-charactersExceeded {
            color: get-color(--error-light);
        }
    }

    .MuiFormHelperText-root {
        margin: 0px;
        font-size: 14px;
    }
}
