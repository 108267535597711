@import '~assets/sass/colors';

.o-resourceTable {
    border: 1px solid get-color(--divider);
    border-radius: 0.5em;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0px;
    }

    &__tableHeader {
        h1 {
            margin: 0 0 10px 0;
        }
        p {
            margin: 0;
        }
        background-color: get-color(--gray-400);
        padding: 10px 20px 10px 20px;
        display: flex;
        border-radius: 0.5em 0.5em 0 0;
        justify-content: space-between;

        &-rightSide {
            display: grid;
            grid-template-columns: 1fr 0.3fr 1fr;
            justify-items: end;

            b {
                margin-left: 15px;
            }
        }

        &-assignee {
            display: flex;
            align-items: baseline;
            padding-bottom: 0.5em;

            h1 {
                margin-left: 0.5em;
                font-size: 18px;
                font-weight: 600;
            }
        }
    }

    &__tasksAllocated {
        font-weight: 600;
    }

    &__taskName {
        margin: 0;
        font-weight: bold;
        color: get-color(--secondary-main);
        font-size: 16px;
        display: flex;
        text-align: left;
        cursor: pointer;
        align-items: center;
    }

    th {
        font-weight: var(--font-weight-bold);
        font-size: 14px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    &__tableRow {
        border: 1px solid get-color(--divider);
        height: 70px;

        &:first-child {
            border-top: 0px;
        }
    }

    &__numberColumn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    &__overAllocationInfoRow {
        display: flex;
        align-content: center;
        color: get-color(--error-main);
        font-weight: 600;

        p {
            font-weight: 600;
            margin: 0;
            padding: 0;
        }
    }

    &__taskAlignedIcon {
        margin-right: 10px;
        color: get-color(--gray-900);
    }

    .notAligned {
        color: get-color(--gray-300);
    }

    .overAligned {
        color: get-color(--error-main);
    }

    &__overAllocatedText {
        margin: 0;
        padding: 0;
    }
}

.bold {
    font-weight: bold;
}
