.o-homePage {
    &__cardContainer {
        display: grid;
        grid-template-rows: auto;
        justify-items: stretch;
        grid-column-gap: 30px;

        &.-long {
            grid-template-columns: 1fr;
            max-width: 60%;
        }
        &.-short {
            grid-template-columns: 1fr 1fr 1fr;
            max-width: 100%;
        }
    }
}
