@import '~assets/sass/colors';

.a-columnContainer {
    position: relative;
    top: 0;
    height: 100%;
    min-height: 40px;
    width: 100%;
    justify-content: space-between;
    display: flex;

    &__divider {
        background: pink !important;
        border-left: 1px solid get-color(--gray-350);
    }
}

.o-three-bars-overlay {
    position: absolute;
    top: 0;
    height: 24px;
    &.overAllocated {
        border: 1px solid #ce2627;
        background: #fdeceb;
    }
    &.amountUsed {
        position: absolute;
        top: 12px;
        height: 1px;
        background: #323a45;
        &__cap {
            border-right: 3px solid #323a45;
        }
    }
    &.totalAmount {
        background: #c0c3c3;
        min-width: 4px;
    }
    &.icon {
        position: absolute;
        top: 2px;
    }
}
