.ally-button {
    width: 100%;
    border: 0px;
    background-color: transparent;
}

.prevent-wrap {
    flex-flow: column nowrap;
}

.-hidden {
    display: none;
}
