@import '~assets/sass/colors';
.o-taskListItem {
    &__taskName {
        font-weight: 600;
        font-size: 14px;
        color: get-color(--primary-main);
    }

    &__dependency {
        display: flex;
        justify-content: center;
    }

    &__interactable {
        cursor: pointer;
    }

    &__taskNameContainer {
        display: flex;
        align-items: center;
    }

    &__dollarSign {
        font-size: 16px;
    }

    &__status {
        button {
            display: flex;
            align-items: center;

            span {
                margin-right: 5px;
            }
        }
    }

    &__estimate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .MuiIcon-root {
            font-weight: bold;
        }
    }

    &__taskGroup {
        padding: 5px 0;
        border-radius: 20px;
        font-weight: bold;
    }

    &__milestone {
        margin-top: 8px;
    }
}
