@import '~assets/sass/colors';

.o-accordion {
    overflow-y: auto;
    margin-bottom: 14px;

    .Mui-expanded {
        border-radius: 4px 4px 0 0 !important;
        overflow: hidden;
    }

    &__heading {
        display: flex;
        align-items: center;

        .MuiIcon-root {
            font-size: 28px;
            color: get-color(--white-hsl);
        }
    }

    &__title {
        margin: 0 0 0 8px;
        color: get-color(--white-hsl);
        font-weight: 600;
    }

    &__toggle {
        color: var(--primary);
        text-decoration: underline;
    }

    &__content {
        height: 0px;
        transition: height 0.5s;
        overflow: hidden;

        &.-open {
            height: 25vh;
            overflow-y: auto;
        }
    }

    &__expandIcon {
        .MuiIconButton-root {
            padding: 0px;
        }
        .MuiIcon-root {
            font-size: 45px;
            color: get-color(--white-hsl);
        }
    }

    .MuiAccordionSummary-expandIcon {
        padding: 0px;

        .Mui-expanded {
            margin: 12px;
        }
    }

    .MuiPaper-root {
        border: none;
    }

    .MuiAccordion-root {
        border-radius: 8px;
    }

    .MuiAccordionSummary-root {
        background-color: get-color(--primary-main);
        border-radius: 4px;
        padding-right: 25px;
    }

    .MuiAccordionDetails-root {
        padding: 0px;
        border: 1px solid get-color(--divider);
    }
}
