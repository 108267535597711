@import '~assets/sass/colors';

.o-taskCard {
    background-clip: padding-box;
    border: 1px solid get-color(--divider);
    border-radius: 6px;
    padding: 8px;
    margin: 0.75em 0;

    &:hover {
        filter: brightness(0.95);
    }

    &__titleContainer {
        cursor: pointer;
    }

    &:first-child {
        margin-top: 0;
    }

    &__listItem {
        margin: 8px 0;
        font-size: 12px;

        .MuiAvatar-root {
            font-size: var(--font-size-normal);
            height: 30px;
            width: 30px;
            border: 1px solid get-color(--gray-800);
        }
    }

    &__name {
        margin: 0 0 5px 0;
        font-size: 14px;
        font-weight: var(--font-weight-semibold);
    }

    &__separatorLine {
        border-bottom: 1px solid get-color(--divider);
        height: 12px;
        margin: 0px 2px 10px 2px;
    }

    &__dependencyList {
        list-style: none;
        margin: 0px;
        padding: 0px;
        width: 200px;

        li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 5px 0;
        }
    }

    &__dependencies {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        color: get-color(--white-hsl);
        background-color: get-color(--primary-main);
        font-size: 12px;
        font-weight: 600;
    }

    &__taskGroupPill {
        padding: 5px 15px;
        border-radius: 20px;
        font-weight: var(--font-weight-semibold);
    }

    &__viewButton {
        font-size: 14px;
        color: get-color(--secondary-main);
        &:hover {
            color: get-color(--secondary-dark);
        }
    }

    &__viewButtonContainer {
        padding: 10px 6px 2px 0;
        display: flex;
        align-items: center;
    }

    .MuiChip-icon {
        color: get-color(--black-hsl);
    }

    .MuiChip-label {
        font-size: 12px;
        padding: 0 4px 0 8px;
    }
}
