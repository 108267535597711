.ganttItem {
    height: 100%;
    animation: GanttItem 0.3s ease forwards;

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;
        background: #ccc;
        white-space: nowrap;
        cursor: default;

        &.-rounded {
            border-radius: 20px;
        }

        &.-smoothed {
            border-radius: 6px;
        }

        &.-boxed {
            border-radius: 2px;
        }

        &.-transparent:hover {
            border: none;
        }

        .center-align {
            display: flex;
            flex-direction: row;
            justify-content: center;
            text-align: center;
            .margin-left {
                margin-left: 6px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
    }

    &__item:hover {
        border: 3px solid rgba(0, 0, 0, 0.1);
    }

    &__empty {
        width: 130px;
        height: 30px;
        border-radius: 32px;
    }
}

@keyframes GanttItem {
    from {
        width: 50%;
        color: transparent;
    }
    to {
        width: 100%;
        color: inherit;
    }
}
