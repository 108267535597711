@import '~assets/sass/colors';

.a-editProjectModal {
    &__title {
        h2,
        span {
            margin: 0;
            font-size: 24px;
            font-weight: var(--font-weight-bold);
        }
        p {
            margin: 0;
        }
    }

    &__content {
        margin-bottom: 2rem;

        .MuiInputLabel-root {
            font-weight: var(--font-weight-semibold);
            font-size: 16px;
            color: get-color(--text-primary);
        }
    }

    &__label {
        color: get-color(--text-primary);
        font-size: 16px;
        span {
            font-weight: var(--font-weight-semibold);
        }

        p {
            margin: 5px 0;
        }
    }

    &__actions {
        padding: 0 31px 28px 31px !important;
    }

    &__closeButton {
        position: absolute;
        right: 0px;
        top: 0px;

        .MuiIconButton-root {
            .MuiIcon-root {
                color: get-color(--black-hsl);
                font-size: 24px;
            }
        }
    }
}
