@import '~assets/sass/colors';

.o-manageTasksCard {
    border: 1px solid get-color(--divider);
    border-radius: 0.5em;
    display: flex;
    flex-direction: column;
    flex-basis: 300px;
    height: 100%;
    overflow: hidden;
    min-width: 300px;
    background-color: get-color(--white-hsl);

    &__header {
        padding: 8px 0px;
        width: 100%;
        -webkit-transition: max-height 1s ease-out;
        -moz-transition: max-height 1s ease-out;
        transition: max-height 1s ease-out;

        &.-assignee {
            padding: 4px 0px;
        }

        &.-expanded {
            .o-manageTasksCard__description {
                p {
                    margin: 14px 0px 0px 0px;
                }
            }
        }

        .o-manageTasksCard__taskGroupTitle {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .MuiIcon-root {
                color: get-color(--black-hsl);
                cursor: pointer;
                font-size: 24px;
            }

            &.taskGroup {
                .MuiIcon-root {
                    margin-left: 20px;
                }
            }

            &.status {
                .MuiIcon-root {
                    margin-right: 5px;
                }
            }
        }

        .header-title {
            font-size: var(--font-size-xlarge);
            font-weight: 600;
            color: get-color(--black-hsl);
        }

        .o-tooltipmenu__icon {
            padding: 0;
        }
    }
    &__accordion-header {
        background-color: get-color(--gray-400) !important;
    }
    .MuiAccordionSummary-content {
        margin: 12px 0 !important;
    }

    .MuiPaper-root {
        border: none;
    }

    .MuiPaper-elevation1 {
        box-shadow: none;
    }

    .MuiAccordionSummary-content.Mui-expanded {
        margin: 12px 0 !important;
    }

    .MuiAccordion-root.Mui-expanded {
        margin: 0 !important;
    }

    .MuiTableRow-root {
        border-bottom: 1px solid get-color(--divider);
    }

    .MuiTableRow-root:last-of-type {
        border-bottom: none;
    }

    .MuiTableBody-root {
        .MuiTableCell-body {
            padding-left: 10px !important;
        }

        .MuiTableCell-root {
            border-right: 1px solid get-color(--divider);
        }

        .MuiTableCell-root:last-of-type {
            border-right: none;
        }
    }

    .MuiTableCell-body {
        padding-left: 10px !important;
    }

    &__taskCount {
        height: 33px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px;
        background-color: get-color(--divider);
        span {
            color: get-color(--deep-gray);
        }
    }

    &__taskList {
        border: 1px solid get-color(--divider);
        flex-grow: 20;
        padding: 0.75em;
        overflow-y: auto;
    }
}
