// sass-lint:disable-block no-url-domains
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&display=swap');
@import url('https://use.fontawesome.com/releases/v5.8.1/css/all.css');

// $csstricks-mono: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
//     'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono',
//     'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;

// Font Fam
$montserrat: 'Montserrat', sans-serif;
$opensans: 'Open Sans', sans-serif;

// Font For Content
$fontstack-body: $montserrat;
$fontstack-headings: $montserrat;

// Font Weight
$global-weight-normal: 400;
$fontweight-thin: lighter;
$fontweight-normal: 400;
$fontweight-bold: 600;

// Font Size
$fontsize-small: 12px;
$fontsize-normal: 14px;
$fontsize-large: 22px;
$fontsize-xl: 30px;
$fontsize-xxl: 48px;
$fontsize-xxxl: 64px;
$fontsize-display: 62px;

h1 {
    font-family: $montserrat, 'san-serif';
    font-size: 24px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
}

h2 {
    font-family: $montserrat, 'san-serif';
    font-size: 20px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
}

h3 {
    font-family: $montserrat, 'san-serif';
    font-size: 18px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
}

h4 {
    font-family: $montserrat, 'san-serif';
    font-size: 14px;
    font-weight: 700;
    margin: 0px 0px 15px 0px;
}

h5 {
    font-size: 12px;
    font-family: $montserrat, 'san-serif';
    font-weight: 700;
}

p,
body {
    font-family: $montserrat, 'san-serif';
    font-size: 14px;
    font-weight: 400;
}

.subHeadline {
    font-family: $montserrat, 'san-serif';
    font-size: 18px;
    font-weight: 700;
    color: var(--dark-green);
    margin-bottom: 15px;
}

//
// Utility function to set both the font-size and
// line height (Important when your text spans more
// than one line).
//
@mixin fontsize($base) {
    font-size: $base;
    line-height: $base * 1.15;
}

@mixin fontsize_altlineheight($base) {
    font-size: $base;
    line-height: $base * 1;
}

.uppercase {
    text-transform: uppercase;
}

.regular {
    font-weight: 400;
}

.italic {
    font-style: italic;
}

.medium {
    font-weight: 600;
}

.bold,
.--bold {
    font-weight: 700;
}

.thin {
    font-weight: lighter;
}

.blue-text {
    color: var(--primary);
}

.red-text {
    color: var(--alert-disabled);
}

.link {
    font-weight: 600;
    border-radius: 0;
    color: #107ab7;
    border-bottom: 2px solid #107ab7;
}
