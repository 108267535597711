@import '~assets/sass/colors';

@keyframes RingProgress {
    0% {
        stroke-dasharray: 0 100;
    }
}

.m-scoreRingWrapper {
    display: flex;
    align-items: center;
    padding: 5px;

    &__definition {
        margin-left: 1.5em;
        font-weight: 600;
        width: 30%;
    }

    &__scoreRing {
        width: 10%;
        circle {
            fill: none;
        }
    }

    &__ring {
        stroke-linecap: round;
    }

    &__background {
        stroke: get-color(--gray-300);
    }

    &__completed {
        stroke: get-color(--gray-800);
        animation: RingProgress 1s ease-in-out forwards;
        stroke-linecap: round;
    }
}
