@import '~assets/sass/colors';

.a-autocompleteDropdown {
    display: flex;
    flex-direction: column;

    &__label {
        color: get-color(--text-secondary);
        padding-left: 12px;
        margin-bottom: 8px;
    }

    .MuiInputBase-root {
        background-color: get-color(--white-hsl);
    }
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        height: 40px;
        padding: 0 0 0 12px;
    }

    .MuiAutocomplete-clearIndicator {
        display: none;
    }
}
