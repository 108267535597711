@import '~assets/sass/colors';

.o-indentify-people-forms {
    padding: 0 !important;

    &__body {
        position: relative;
        height: 100%;

        .m-forms-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 10px 15px 0 15px;

            &__child {
                @extend .m-forms-header;
                border-bottom: none;
                padding-top: 0;
                border-top: 1px solid get-color(--divider);
            }

            > h2 {
                margin-top: 12px;
            }
        }

        .m-forms-sections {
            min-height: 100%;
            .__no-border {
                border: none !important;
            }
            .a-forms-section {
                padding: 15px;
                padding-bottom: 0;
                border-top: 1px solid get-color(--divider);
                position: relative;

                .a-phoneType {
                    position: relative;
                    top: -30px;
                    color: get-color(--text-disabled);
                }

                > section {
                    padding-bottom: 20px;

                    > div > div > label {
                        color: get-color(--text);
                    }
                }

                .-nudge {
                    > div > div > input {
                        margin-top: 10px;
                    }
                }

                > div {
                    position: absolute;
                    top: 34px;
                    font-size: 12px;
                }

                .a-resend-button {
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                }

                .a-entry {
                    position: relative;
                    top: 0;
                    width: 100%;

                    &__label {
                        align-items: center;
                        box-sizing: border-box;
                        display: block;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 16px;
                        padding-bottom: 5px;
                    }
                    &__data {
                        width: 100%;
                        display: grid;
                        grid-template-columns: auto 1fr;
                        grid-gap: 4px;
                        font-size: 14px;
                        color: rgb(164, 168, 168);
                        margin-top: 10px;

                        > span {
                            color: get-color(--error-dark);
                        }
                    }
                }
            }
        }

        .m-forms-sections-footer {
            position: fixed;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: flex-end;
            padding: 15px;
            width: 400px;
            background: get-color(--white-hsl);
            border-top: 1px solid get-color(--divider);

            > button:first-child {
                margin-right: 10px;
            }
        }
    }
}

.o-add-people-modal {
    display: grid;
    grid-gap: 20px;

    &__forms {
        display: grid;
        grid-template-columns: 1fr auto;
        grid-gap: 10px;
        > section > div > div {
            margin-top: 0 !important;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        button:first-child {
            margin-right: 10px;
        }
    }

    .email-row {
        margin-top: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 6px;
        > button {
            height: 28px;
            width: 28px;
        }
        > div {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .-errorMessage {
            color: get-color(--error-dark);
            > div {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 6px;

                &.__small {
                    font-size: 12px;
                }
            }
        }
    }
}

.o-identify-people-table {
    border: 2px solid get-color(--divider);
    border-radius: 10px;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
    margin-bottom: 40px;
    background: get-color(--white-hsl);

    .m-identify-people-table-header {
        background: get-color(--divider);
        font-weight: 700;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: center;

        > div:first-child {
            min-width: 260px;
        }

        > div {
            width: 100%;
            padding: 6px;
        }
    }

    .m-identify-people-table-body {
        .m-identify-people-table-body-row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid get-color(--divider);

            > div:first-child {
                min-width: 260px;
                text-align: left;
            }

            > div {
                width: 100%;
                padding: 10px;
                border-right: 1px solid get-color(--divider);
                display: grid;
                grid-template-columns: auto 1fr;
                grid-gap: 10px;
                text-align: center;
            }

            .a-cell {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0;
            }

            .--name {
                font-weight: 700;
                color: var(--primary);
            }

            > div:last-child {
                border-right: none;
            }
        }
    }

    .m-identify-people-table-footer {
        .a-addPersonButton {
            border: 1px solid get-color(--divider);
            border-top-right-radius: 0px;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 0.5em;
            border-bottom-left-radius: 0.5em;

            .o-addPersonButton__text {
                font-size: var(--font-size-normal);
            }
            button {
                width: 100%;
            }

            .-alignLeft {
                display: flex;
                justify-content: flex-start;
            }

            .MuiIcon-root {
                font-size: 24px;
            }

            .MuiButton-root {
                background-color: get-color(--white-hsl);
                color: get-color(--secondary-main);
                padding: 12px 12px;
                &:hover {
                    color: get-color(--white-hsl);
                    background-color: get-color(--secondary-main);
                }
            }
        }
    }
}
