.o-plan {
    width: 100%;

    &__planName {
        font-weight: bold;
        font-size: 1.7em;
        margin: 0px 0px 15px 0px;
    }

    &__planCode {
        font-weight: bold;
        margin-top: 0;
    }

    &__description {
        width: 45%;
        font-size: 1em;
    }
}
