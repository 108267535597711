@import '~assets/sass/colors';

.m-identifyProblem {
    &__prioritySelect {
        margin-bottom: 14px;
        .MuiInputBase-input {
            border-radius: 4px;
            padding: 10px 26px 10px 12px;
            border: 1px solid #ced4da;

            &:focus {
                border-radius: 4px;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
            }
        }
    }
}
