/**
 * Foundation for Sites
 * Version 6.6.3
 * https://get.foundation
 * Licensed under MIT Open Source
 */
.grid-container {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: rem-calc(1200);
    margin-left: auto;
    margin-right: auto;
}
@media print, screen and (min-width: 40em) {
    .grid-container {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}
.grid-container.fluid {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
@media print, screen and (min-width: 40em) {
    .grid-container.fluid {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}
.grid-container.full {
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.grid-x {
    display: flex;
    flex-flow: row wrap;
}

.cell.auto {
    flex: 1 1 0px;
}
.cell.shrink {
    flex: 0 0 auto;
}

.grid-x > .auto {
    width: auto;
}
.grid-x > .shrink {
    width: auto;
}

.grid-x > .small-shrink,
.grid-x > .small-full,
.grid-x > .small-1,
.grid-x > .small-2,
.grid-x > .small-3,
.grid-x > .small-4,
.grid-x > .small-5,
.grid-x > .small-6,
.grid-x > .small-7,
.grid-x > .small-8,
.grid-x > .small-9,
.grid-x > .small-10,
.grid-x > .small-11,
.grid-x > .small-12,
.grid-x > .small-13,
.grid-x > .small-14,
.grid-x > .small-15,
.grid-x > .small-16,
.grid-x > .small-17,
.grid-x > .small-18,
.grid-x > .small-19,
.grid-x > .small-20,
.grid-x > .small-21,
.grid-x > .small-22,
.grid-x > .small-23,
.grid-x > .small-24 {
    flex-basis: auto;
}

@media print, screen and (min-width: 40em) {
    .grid-x > .medium-shrink,
    .grid-x > .medium-full,
    .grid-x > .medium-1,
    .grid-x > .medium-2,
    .grid-x > .medium-3,
    .grid-x > .medium-4,
    .grid-x > .medium-5,
    .grid-x > .medium-6,
    .grid-x > .medium-7,
    .grid-x > .medium-8,
    .grid-x > .medium-9,
    .grid-x > .medium-10,
    .grid-x > .medium-11,
    .grid-x > .medium-12,
    .grid-x > .medium-13,
    .grid-x > .medium-14,
    .grid-x > .medium-15,
    .grid-x > .medium-16,
    .grid-x > .medium-17,
    .grid-x > .medium-18,
    .grid-x > .medium-19,
    .grid-x > .medium-20,
    .grid-x > .medium-21,
    .grid-x > .medium-22,
    .grid-x > .medium-23,
    .grid-x > .medium-24 {
        flex-basis: auto;
    }
}
@media print, screen and (min-width: 64em) {
    .grid-x > .large-shrink,
    .grid-x > .large-full,
    .grid-x > .large-1,
    .grid-x > .large-2,
    .grid-x > .large-3,
    .grid-x > .large-4,
    .grid-x > .large-5,
    .grid-x > .large-6,
    .grid-x > .large-7,
    .grid-x > .large-8,
    .grid-x > .large-9,
    .grid-x > .large-10,
    .grid-x > .large-11,
    .grid-x > .large-12,
    .grid-x > .large-13,
    .grid-x > .large-14,
    .grid-x > .large-15,
    .grid-x > .large-16,
    .grid-x > .large-17,
    .grid-x > .large-18,
    .grid-x > .large-19,
    .grid-x > .large-20,
    .grid-x > .large-21,
    .grid-x > .large-22,
    .grid-x > .large-23,
    .grid-x > .large-24 {
        flex-basis: auto;
    }
}
.grid-x > .small-24,
.grid-x > .small-23,
.grid-x > .small-22,
.grid-x > .small-21,
.grid-x > .small-20,
.grid-x > .small-19,
.grid-x > .small-18,
.grid-x > .small-17,
.grid-x > .small-16,
.grid-x > .small-15,
.grid-x > .small-14,
.grid-x > .small-13,
.grid-x > .small-12,
.grid-x > .small-11,
.grid-x > .small-10,
.grid-x > .small-9,
.grid-x > .small-8,
.grid-x > .small-7,
.grid-x > .small-6,
.grid-x > .small-5,
.grid-x > .small-4,
.grid-x > .small-3,
.grid-x > .small-2,
.grid-x > .small-1 {
    flex: 0 0 auto;
}

.grid-x > .small-1 {
    width: 4.1666666667%;
}

.grid-x > .small-2 {
    width: 8.3333333333%;
}

.grid-x > .small-3 {
    width: 12.5%;
}

.grid-x > .small-4 {
    width: 16.6666666667%;
}

.grid-x > .small-5 {
    width: 20.8333333333%;
}

.grid-x > .small-6 {
    width: 25%;
}

.grid-x > .small-7 {
    width: 29.1666666667%;
}

.grid-x > .small-8 {
    width: 33.3333333333%;
}

.grid-x > .small-9 {
    width: 37.5%;
}

.grid-x > .small-10 {
    width: 41.6666666667%;
}

.grid-x > .small-11 {
    width: 45.8333333333%;
}

.grid-x > .small-12 {
    width: 50%;
}

.grid-x > .small-13 {
    width: 54.1666666667%;
}

.grid-x > .small-14 {
    width: 58.3333333333%;
}

.grid-x > .small-15 {
    width: 62.5%;
}

.grid-x > .small-16 {
    width: 66.6666666667%;
}

.grid-x > .small-17 {
    width: 70.8333333333%;
}

.grid-x > .small-18 {
    width: 75%;
}

.grid-x > .small-19 {
    width: 79.1666666667%;
}

.grid-x > .small-20 {
    width: 83.3333333333%;
}

.grid-x > .small-21 {
    width: 87.5%;
}

.grid-x > .small-22 {
    width: 91.6666666667%;
}

.grid-x > .small-23 {
    width: 95.8333333333%;
}

.grid-x > .small-24 {
    width: 100%;
}

@media print, screen and (min-width: 40em) {
    .grid-x > .medium-24,
    .grid-x > .medium-23,
    .grid-x > .medium-22,
    .grid-x > .medium-21,
    .grid-x > .medium-20,
    .grid-x > .medium-19,
    .grid-x > .medium-18,
    .grid-x > .medium-17,
    .grid-x > .medium-16,
    .grid-x > .medium-15,
    .grid-x > .medium-14,
    .grid-x > .medium-13,
    .grid-x > .medium-12,
    .grid-x > .medium-11,
    .grid-x > .medium-10,
    .grid-x > .medium-9,
    .grid-x > .medium-8,
    .grid-x > .medium-7,
    .grid-x > .medium-6,
    .grid-x > .medium-5,
    .grid-x > .medium-4,
    .grid-x > .medium-3,
    .grid-x > .medium-2,
    .grid-x > .medium-1,
    .grid-x > .medium-shrink {
        flex: 0 0 auto;
    }

    .grid-x > .medium-shrink {
        width: auto;
    }

    .grid-x > .medium-1 {
        width: 4.1666666667%;
    }

    .grid-x > .medium-2 {
        width: 8.3333333333%;
    }

    .grid-x > .medium-3 {
        width: 12.5%;
    }

    .grid-x > .medium-4 {
        width: 16.6666666667%;
    }

    .grid-x > .medium-5 {
        width: 20.8333333333%;
    }

    .grid-x > .medium-6 {
        width: 25%;
    }

    .grid-x > .medium-7 {
        width: 29.1666666667%;
    }

    .grid-x > .medium-8 {
        width: 33.3333333333%;
    }

    .grid-x > .medium-9 {
        width: 37.5%;
    }

    .grid-x > .medium-10 {
        width: 41.6666666667%;
    }

    .grid-x > .medium-11 {
        width: 45.8333333333%;
    }

    .grid-x > .medium-12 {
        width: 50%;
    }

    .grid-x > .medium-13 {
        width: 54.1666666667%;
    }

    .grid-x > .medium-14 {
        width: 58.3333333333%;
    }

    .grid-x > .medium-15 {
        width: 62.5%;
    }

    .grid-x > .medium-16 {
        width: 66.6666666667%;
    }

    .grid-x > .medium-17 {
        width: 70.8333333333%;
    }

    .grid-x > .medium-18 {
        width: 75%;
    }

    .grid-x > .medium-19 {
        width: 79.1666666667%;
    }

    .grid-x > .medium-20 {
        width: 83.3333333333%;
    }

    .grid-x > .medium-21 {
        width: 87.5%;
    }

    .grid-x > .medium-22 {
        width: 91.6666666667%;
    }

    .grid-x > .medium-23 {
        width: 95.8333333333%;
    }

    .grid-x > .medium-24 {
        width: 100%;
    }
}
@media print, screen and (min-width: 64em) {
    .grid-x > .large-24,
    .grid-x > .large-23,
    .grid-x > .large-22,
    .grid-x > .large-21,
    .grid-x > .large-20,
    .grid-x > .large-19,
    .grid-x > .large-18,
    .grid-x > .large-17,
    .grid-x > .large-16,
    .grid-x > .large-15,
    .grid-x > .large-14,
    .grid-x > .large-13,
    .grid-x > .large-12,
    .grid-x > .large-11,
    .grid-x > .large-10,
    .grid-x > .large-9,
    .grid-x > .large-8,
    .grid-x > .large-7,
    .grid-x > .large-6,
    .grid-x > .large-5,
    .grid-x > .large-4,
    .grid-x > .large-3,
    .grid-x > .large-2,
    .grid-x > .large-1,
    .grid-x > .large-shrink {
        flex: 0 0 auto;
    }

    .grid-x > .large-shrink {
        width: auto;
    }

    .grid-x > .large-1 {
        width: 4.1666666667%;
    }

    .grid-x > .large-2 {
        width: 8.3333333333%;
    }

    .grid-x > .large-3 {
        width: 12.5%;
    }

    .grid-x > .large-4 {
        width: 16.6666666667%;
    }

    .grid-x > .large-5 {
        width: 20.8333333333%;
    }

    .grid-x > .large-6 {
        width: 25%;
    }

    .grid-x > .large-7 {
        width: 29.1666666667%;
    }

    .grid-x > .large-8 {
        width: 33.3333333333%;
    }

    .grid-x > .large-9 {
        width: 37.5%;
    }

    .grid-x > .large-10 {
        width: 41.6666666667%;
    }

    .grid-x > .large-11 {
        width: 45.8333333333%;
    }

    .grid-x > .large-12 {
        width: 50%;
    }

    .grid-x > .large-13 {
        width: 54.1666666667%;
    }

    .grid-x > .large-14 {
        width: 58.3333333333%;
    }

    .grid-x > .large-15 {
        width: 62.5%;
    }

    .grid-x > .large-16 {
        width: 66.6666666667%;
    }

    .grid-x > .large-17 {
        width: 70.8333333333%;
    }

    .grid-x > .large-18 {
        width: 75%;
    }

    .grid-x > .large-19 {
        width: 79.1666666667%;
    }

    .grid-x > .large-20 {
        width: 83.3333333333%;
    }

    .grid-x > .large-21 {
        width: 87.5%;
    }

    .grid-x > .large-22 {
        width: 91.6666666667%;
    }

    .grid-x > .large-23 {
        width: 95.8333333333%;
    }

    .grid-x > .large-24 {
        width: 100%;
    }
}
.grid-margin-x:not(.grid-x) > .cell {
    width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
    height: auto;
}

.grid-margin-x {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-margin-x {
        margin-left: -0.9375rem;
        margin-right: -0.9375rem;
    }
}
.grid-margin-x > .cell {
    width: calc(100% - 1.25rem);
    margin-left: 0.625rem;
    margin-right: 0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-margin-x > .cell {
        width: calc(100% - 1.876rem);
        margin-left: 0.9375rem;
        margin-right: 0.9375rem;
    }
}
.grid-margin-x > .auto {
    width: auto;
}
.grid-margin-x > .shrink {
    width: auto;
}
.grid-margin-x > .small-1 {
    width: calc(4.1666666667% - 1.25rem);
}
.grid-margin-x > .small-2 {
    width: calc(8.3333333333% - 1.25rem);
}
.grid-margin-x > .small-3 {
    width: calc(12.5% - 1.25rem);
}
.grid-margin-x > .small-4 {
    width: calc(16.6666666667% - 1.25rem);
}
.grid-margin-x > .small-5 {
    width: calc(20.8333333333% - 1.25rem);
}
.grid-margin-x > .small-6 {
    width: calc(25% - 1.25rem);
}
.grid-margin-x > .small-7 {
    width: calc(29.1666666667% - 1.25rem);
}
.grid-margin-x > .small-8 {
    width: calc(33.3333333333% - 1.25rem);
}
.grid-margin-x > .small-9 {
    width: calc(37.5% - 1.25rem);
}
.grid-margin-x > .small-10 {
    width: calc(41.6666666667% - 1.25rem);
}
.grid-margin-x > .small-11 {
    width: calc(45.8333333333% - 1.25rem);
}
.grid-margin-x > .small-12 {
    width: calc(50% - 1.25rem);
}
.grid-margin-x > .small-13 {
    width: calc(54.1666666667% - 1.25rem);
}
.grid-margin-x > .small-14 {
    width: calc(58.3333333333% - 1.25rem);
}
.grid-margin-x > .small-15 {
    width: calc(62.5% - 1.25rem);
}
.grid-margin-x > .small-16 {
    width: calc(66.6666666667% - 1.25rem);
}
.grid-margin-x > .small-17 {
    width: calc(70.8333333333% - 1.25rem);
}
.grid-margin-x > .small-18 {
    width: calc(75% - 1.25rem);
}
.grid-margin-x > .small-19 {
    width: calc(79.1666666667% - 1.25rem);
}
.grid-margin-x > .small-20 {
    width: calc(83.3333333333% - 1.25rem);
}
.grid-margin-x > .small-21 {
    width: calc(87.5% - 1.25rem);
}
.grid-margin-x > .small-22 {
    width: calc(91.6666666667% - 1.25rem);
}
.grid-margin-x > .small-23 {
    width: calc(95.8333333333% - 1.25rem);
}
.grid-margin-x > .small-24 {
    width: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
    .grid-margin-x > .auto {
        width: auto;
    }
    .grid-margin-x > .shrink {
        width: auto;
    }
    .grid-margin-x > .small-1 {
        width: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-x > .small-2 {
        width: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-x > .small-3 {
        width: calc(12.5% - 1.876rem);
    }
    .grid-margin-x > .small-4 {
        width: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-x > .small-5 {
        width: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-x > .small-6 {
        width: calc(25% - 1.876rem);
    }
    .grid-margin-x > .small-7 {
        width: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-x > .small-8 {
        width: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-x > .small-9 {
        width: calc(37.5% - 1.876rem);
    }
    .grid-margin-x > .small-10 {
        width: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-x > .small-11 {
        width: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-x > .small-12 {
        width: calc(50% - 1.876rem);
    }
    .grid-margin-x > .small-13 {
        width: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-x > .small-14 {
        width: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-x > .small-15 {
        width: calc(62.5% - 1.876rem);
    }
    .grid-margin-x > .small-16 {
        width: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-x > .small-17 {
        width: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-x > .small-18 {
        width: calc(75% - 1.876rem);
    }
    .grid-margin-x > .small-19 {
        width: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-x > .small-20 {
        width: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-x > .small-21 {
        width: calc(87.5% - 1.876rem);
    }
    .grid-margin-x > .small-22 {
        width: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-x > .small-23 {
        width: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-x > .small-24 {
        width: calc(100% - 1.876rem);
    }
    .grid-margin-x > .medium-auto {
        width: auto;
    }
    .grid-margin-x > .medium-shrink {
        width: auto;
    }
    .grid-margin-x > .medium-1 {
        width: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-2 {
        width: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-3 {
        width: calc(12.5% - 1.876rem);
    }
    .grid-margin-x > .medium-4 {
        width: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-5 {
        width: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-6 {
        width: calc(25% - 1.876rem);
    }
    .grid-margin-x > .medium-7 {
        width: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-8 {
        width: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-9 {
        width: calc(37.5% - 1.876rem);
    }
    .grid-margin-x > .medium-10 {
        width: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-11 {
        width: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-12 {
        width: calc(50% - 1.876rem);
    }
    .grid-margin-x > .medium-13 {
        width: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-14 {
        width: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-15 {
        width: calc(62.5% - 1.876rem);
    }
    .grid-margin-x > .medium-16 {
        width: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-17 {
        width: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-18 {
        width: calc(75% - 1.876rem);
    }
    .grid-margin-x > .medium-19 {
        width: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-20 {
        width: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-21 {
        width: calc(87.5% - 1.876rem);
    }
    .grid-margin-x > .medium-22 {
        width: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-x > .medium-23 {
        width: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-x > .medium-24 {
        width: calc(100% - 1.876rem);
    }
}
@media print, screen and (min-width: 64em) {
    .grid-margin-x > .large-auto {
        width: auto;
    }
    .grid-margin-x > .large-shrink {
        width: auto;
    }
    .grid-margin-x > .large-1 {
        width: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-x > .large-2 {
        width: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-x > .large-3 {
        width: calc(12.5% - 1.876rem);
    }
    .grid-margin-x > .large-4 {
        width: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-x > .large-5 {
        width: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-x > .large-6 {
        width: calc(25% - 1.876rem);
    }
    .grid-margin-x > .large-7 {
        width: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-x > .large-8 {
        width: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-x > .large-9 {
        width: calc(37.5% - 1.876rem);
    }
    .grid-margin-x > .large-10 {
        width: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-x > .large-11 {
        width: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-x > .large-12 {
        width: calc(50% - 1.876rem);
    }
    .grid-margin-x > .large-13 {
        width: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-x > .large-14 {
        width: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-x > .large-15 {
        width: calc(62.5% - 1.876rem);
    }
    .grid-margin-x > .large-16 {
        width: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-x > .large-17 {
        width: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-x > .large-18 {
        width: calc(75% - 1.876rem);
    }
    .grid-margin-x > .large-19 {
        width: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-x > .large-20 {
        width: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-x > .large-21 {
        width: calc(87.5% - 1.876rem);
    }
    .grid-margin-x > .large-22 {
        width: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-x > .large-23 {
        width: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-x > .large-24 {
        width: calc(100% - 1.876rem);
    }
}

.grid-padding-x .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-padding-x .grid-padding-x {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
    }
}
.grid-container:not(.full) > .grid-padding-x {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-container:not(.full) > .grid-padding-x {
        margin-right: -0.9375rem;
        margin-left: -0.9375rem;
    }
}
.grid-padding-x > .cell {
    padding-right: 0.625rem;
    padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-padding-x > .cell {
        padding-right: 0.9375rem;
        padding-left: 0.9375rem;
    }
}

.small-up-1 > .cell {
    width: 100%;
}

.small-up-2 > .cell {
    width: 50%;
}

.small-up-3 > .cell {
    width: 33.3333333333%;
}

.small-up-4 > .cell {
    width: 25%;
}

.small-up-5 > .cell {
    width: 20%;
}

.small-up-6 > .cell {
    width: 16.6666666667%;
}

.small-up-7 > .cell {
    width: 14.2857142857%;
}

.small-up-8 > .cell {
    width: 12.5%;
}

@media print, screen and (min-width: 40em) {
    .medium-up-1 > .cell {
        width: 100%;
    }

    .medium-up-2 > .cell {
        width: 50%;
    }

    .medium-up-3 > .cell {
        width: 33.3333333333%;
    }

    .medium-up-4 > .cell {
        width: 25%;
    }

    .medium-up-5 > .cell {
        width: 20%;
    }

    .medium-up-6 > .cell {
        width: 16.6666666667%;
    }

    .medium-up-7 > .cell {
        width: 14.2857142857%;
    }

    .medium-up-8 > .cell {
        width: 12.5%;
    }
}
@media print, screen and (min-width: 64em) {
    .large-up-1 > .cell {
        width: 100%;
    }

    .large-up-2 > .cell {
        width: 50%;
    }

    .large-up-3 > .cell {
        width: 33.3333333333%;
    }

    .large-up-4 > .cell {
        width: 25%;
    }

    .large-up-5 > .cell {
        width: 20%;
    }

    .large-up-6 > .cell {
        width: 16.6666666667%;
    }

    .large-up-7 > .cell {
        width: 14.2857142857%;
    }

    .large-up-8 > .cell {
        width: 12.5%;
    }
}
.grid-margin-x.small-up-1 > .cell {
    width: calc(100% - 1.25rem);
}

.grid-margin-x.small-up-2 > .cell {
    width: calc(50% - 1.25rem);
}

.grid-margin-x.small-up-3 > .cell {
    width: calc(33.3333333333% - 1.25rem);
}

.grid-margin-x.small-up-4 > .cell {
    width: calc(25% - 1.25rem);
}

.grid-margin-x.small-up-5 > .cell {
    width: calc(20% - 1.25rem);
}

.grid-margin-x.small-up-6 > .cell {
    width: calc(16.6666666667% - 1.25rem);
}

.grid-margin-x.small-up-7 > .cell {
    width: calc(14.2857142857% - 1.25rem);
}

.grid-margin-x.small-up-8 > .cell {
    width: calc(12.5% - 1.25rem);
}

@media print, screen and (min-width: 40em) {
    .grid-margin-x.small-up-1 > .cell {
        width: calc(100% - 1.876rem);
    }

    .grid-margin-x.small-up-2 > .cell {
        width: calc(50% - 1.876rem);
    }

    .grid-margin-x.small-up-3 > .cell {
        width: calc(33.3333333333% - 1.876rem);
    }

    .grid-margin-x.small-up-4 > .cell {
        width: calc(25% - 1.876rem);
    }

    .grid-margin-x.small-up-5 > .cell {
        width: calc(20% - 1.876rem);
    }

    .grid-margin-x.small-up-6 > .cell {
        width: calc(16.6666666667% - 1.876rem);
    }

    .grid-margin-x.small-up-7 > .cell {
        width: calc(14.2857142857% - 1.876rem);
    }

    .grid-margin-x.small-up-8 > .cell {
        width: calc(12.5% - 1.876rem);
    }

    .grid-margin-x.medium-up-1 > .cell {
        width: calc(100% - 1.876rem);
    }

    .grid-margin-x.medium-up-2 > .cell {
        width: calc(50% - 1.876rem);
    }

    .grid-margin-x.medium-up-3 > .cell {
        width: calc(33.3333333333% - 1.876rem);
    }

    .grid-margin-x.medium-up-4 > .cell {
        width: calc(25% - 1.876rem);
    }

    .grid-margin-x.medium-up-5 > .cell {
        width: calc(20% - 1.876rem);
    }

    .grid-margin-x.medium-up-6 > .cell {
        width: calc(16.6666666667% - 1.876rem);
    }

    .grid-margin-x.medium-up-7 > .cell {
        width: calc(14.2857142857% - 1.876rem);
    }

    .grid-margin-x.medium-up-8 > .cell {
        width: calc(12.5% - 1.876rem);
    }
}
@media print, screen and (min-width: 64em) {
    .grid-margin-x.large-up-1 > .cell {
        width: calc(100% - 1.876rem);
    }

    .grid-margin-x.large-up-2 > .cell {
        width: calc(50% - 1.876rem);
    }

    .grid-margin-x.large-up-3 > .cell {
        width: calc(33.3333333333% - 1.876rem);
    }

    .grid-margin-x.large-up-4 > .cell {
        width: calc(25% - 1.876rem);
    }

    .grid-margin-x.large-up-5 > .cell {
        width: calc(20% - 1.876rem);
    }

    .grid-margin-x.large-up-6 > .cell {
        width: calc(16.6666666667% - 1.876rem);
    }

    .grid-margin-x.large-up-7 > .cell {
        width: calc(14.2857142857% - 1.876rem);
    }

    .grid-margin-x.large-up-8 > .cell {
        width: calc(12.5% - 1.876rem);
    }
}
.small-margin-collapse {
    margin-right: 0;
    margin-left: 0;
}
.small-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
}
.small-margin-collapse > .small-1 {
    width: 4.1666666667%;
}
.small-margin-collapse > .small-2 {
    width: 8.3333333333%;
}
.small-margin-collapse > .small-3 {
    width: 12.5%;
}
.small-margin-collapse > .small-4 {
    width: 16.6666666667%;
}
.small-margin-collapse > .small-5 {
    width: 20.8333333333%;
}
.small-margin-collapse > .small-6 {
    width: 25%;
}
.small-margin-collapse > .small-7 {
    width: 29.1666666667%;
}
.small-margin-collapse > .small-8 {
    width: 33.3333333333%;
}
.small-margin-collapse > .small-9 {
    width: 37.5%;
}
.small-margin-collapse > .small-10 {
    width: 41.6666666667%;
}
.small-margin-collapse > .small-11 {
    width: 45.8333333333%;
}
.small-margin-collapse > .small-12 {
    width: 50%;
}
.small-margin-collapse > .small-13 {
    width: 54.1666666667%;
}
.small-margin-collapse > .small-14 {
    width: 58.3333333333%;
}
.small-margin-collapse > .small-15 {
    width: 62.5%;
}
.small-margin-collapse > .small-16 {
    width: 66.6666666667%;
}
.small-margin-collapse > .small-17 {
    width: 70.8333333333%;
}
.small-margin-collapse > .small-18 {
    width: 75%;
}
.small-margin-collapse > .small-19 {
    width: 79.1666666667%;
}
.small-margin-collapse > .small-20 {
    width: 83.3333333333%;
}
.small-margin-collapse > .small-21 {
    width: 87.5%;
}
.small-margin-collapse > .small-22 {
    width: 91.6666666667%;
}
.small-margin-collapse > .small-23 {
    width: 95.8333333333%;
}
.small-margin-collapse > .small-24 {
    width: 100%;
}
.small-margin-collapse > .medium-1 {
    width: 4.1666666667%;
}
.small-margin-collapse > .medium-2 {
    width: 8.3333333333%;
}
.small-margin-collapse > .medium-3 {
    width: 12.5%;
}
.small-margin-collapse > .medium-4 {
    width: 16.6666666667%;
}
.small-margin-collapse > .medium-5 {
    width: 20.8333333333%;
}
.small-margin-collapse > .medium-6 {
    width: 25%;
}
.small-margin-collapse > .medium-7 {
    width: 29.1666666667%;
}
.small-margin-collapse > .medium-8 {
    width: 33.3333333333%;
}
.small-margin-collapse > .medium-9 {
    width: 37.5%;
}
.small-margin-collapse > .medium-10 {
    width: 41.6666666667%;
}
.small-margin-collapse > .medium-11 {
    width: 45.8333333333%;
}
.small-margin-collapse > .medium-12 {
    width: 50%;
}
.small-margin-collapse > .medium-13 {
    width: 54.1666666667%;
}
.small-margin-collapse > .medium-14 {
    width: 58.3333333333%;
}
.small-margin-collapse > .medium-15 {
    width: 62.5%;
}
.small-margin-collapse > .medium-16 {
    width: 66.6666666667%;
}
.small-margin-collapse > .medium-17 {
    width: 70.8333333333%;
}
.small-margin-collapse > .medium-18 {
    width: 75%;
}
.small-margin-collapse > .medium-19 {
    width: 79.1666666667%;
}
.small-margin-collapse > .medium-20 {
    width: 83.3333333333%;
}
.small-margin-collapse > .medium-21 {
    width: 87.5%;
}
.small-margin-collapse > .medium-22 {
    width: 91.6666666667%;
}
.small-margin-collapse > .medium-23 {
    width: 95.8333333333%;
}
.small-margin-collapse > .medium-24 {
    width: 100%;
}
.small-margin-collapse > .large-1 {
    width: 4.1666666667%;
}
.small-margin-collapse > .large-2 {
    width: 8.3333333333%;
}
.small-margin-collapse > .large-3 {
    width: 12.5%;
}
.small-margin-collapse > .large-4 {
    width: 16.6666666667%;
}
.small-margin-collapse > .large-5 {
    width: 20.8333333333%;
}
.small-margin-collapse > .large-6 {
    width: 25%;
}
.small-margin-collapse > .large-7 {
    width: 29.1666666667%;
}
.small-margin-collapse > .large-8 {
    width: 33.3333333333%;
}
.small-margin-collapse > .large-9 {
    width: 37.5%;
}
.small-margin-collapse > .large-10 {
    width: 41.6666666667%;
}
.small-margin-collapse > .large-11 {
    width: 45.8333333333%;
}
.small-margin-collapse > .large-12 {
    width: 50%;
}
.small-margin-collapse > .large-13 {
    width: 54.1666666667%;
}
.small-margin-collapse > .large-14 {
    width: 58.3333333333%;
}
.small-margin-collapse > .large-15 {
    width: 62.5%;
}
.small-margin-collapse > .large-16 {
    width: 66.6666666667%;
}
.small-margin-collapse > .large-17 {
    width: 70.8333333333%;
}
.small-margin-collapse > .large-18 {
    width: 75%;
}
.small-margin-collapse > .large-19 {
    width: 79.1666666667%;
}
.small-margin-collapse > .large-20 {
    width: 83.3333333333%;
}
.small-margin-collapse > .large-21 {
    width: 87.5%;
}
.small-margin-collapse > .large-22 {
    width: 91.6666666667%;
}
.small-margin-collapse > .large-23 {
    width: 95.8333333333%;
}
.small-margin-collapse > .large-24 {
    width: 100%;
}

.small-padding-collapse {
    margin-right: 0;
    margin-left: 0;
}
.small-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
}

@media print, screen and (min-width: 40em) {
    .medium-margin-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .medium-margin-collapse > .cell {
        margin-right: 0;
        margin-left: 0;
    }
}
@media print, screen and (min-width: 40em) {
    .medium-margin-collapse > .small-1 {
        width: 4.1666666667%;
    }
    .medium-margin-collapse > .small-2 {
        width: 8.3333333333%;
    }
    .medium-margin-collapse > .small-3 {
        width: 12.5%;
    }
    .medium-margin-collapse > .small-4 {
        width: 16.6666666667%;
    }
    .medium-margin-collapse > .small-5 {
        width: 20.8333333333%;
    }
    .medium-margin-collapse > .small-6 {
        width: 25%;
    }
    .medium-margin-collapse > .small-7 {
        width: 29.1666666667%;
    }
    .medium-margin-collapse > .small-8 {
        width: 33.3333333333%;
    }
    .medium-margin-collapse > .small-9 {
        width: 37.5%;
    }
    .medium-margin-collapse > .small-10 {
        width: 41.6666666667%;
    }
    .medium-margin-collapse > .small-11 {
        width: 45.8333333333%;
    }
    .medium-margin-collapse > .small-12 {
        width: 50%;
    }
    .medium-margin-collapse > .small-13 {
        width: 54.1666666667%;
    }
    .medium-margin-collapse > .small-14 {
        width: 58.3333333333%;
    }
    .medium-margin-collapse > .small-15 {
        width: 62.5%;
    }
    .medium-margin-collapse > .small-16 {
        width: 66.6666666667%;
    }
    .medium-margin-collapse > .small-17 {
        width: 70.8333333333%;
    }
    .medium-margin-collapse > .small-18 {
        width: 75%;
    }
    .medium-margin-collapse > .small-19 {
        width: 79.1666666667%;
    }
    .medium-margin-collapse > .small-20 {
        width: 83.3333333333%;
    }
    .medium-margin-collapse > .small-21 {
        width: 87.5%;
    }
    .medium-margin-collapse > .small-22 {
        width: 91.6666666667%;
    }
    .medium-margin-collapse > .small-23 {
        width: 95.8333333333%;
    }
    .medium-margin-collapse > .small-24 {
        width: 100%;
    }
}
@media print, screen and (min-width: 40em) {
    .medium-margin-collapse > .medium-1 {
        width: 4.1666666667%;
    }
    .medium-margin-collapse > .medium-2 {
        width: 8.3333333333%;
    }
    .medium-margin-collapse > .medium-3 {
        width: 12.5%;
    }
    .medium-margin-collapse > .medium-4 {
        width: 16.6666666667%;
    }
    .medium-margin-collapse > .medium-5 {
        width: 20.8333333333%;
    }
    .medium-margin-collapse > .medium-6 {
        width: 25%;
    }
    .medium-margin-collapse > .medium-7 {
        width: 29.1666666667%;
    }
    .medium-margin-collapse > .medium-8 {
        width: 33.3333333333%;
    }
    .medium-margin-collapse > .medium-9 {
        width: 37.5%;
    }
    .medium-margin-collapse > .medium-10 {
        width: 41.6666666667%;
    }
    .medium-margin-collapse > .medium-11 {
        width: 45.8333333333%;
    }
    .medium-margin-collapse > .medium-12 {
        width: 50%;
    }
    .medium-margin-collapse > .medium-13 {
        width: 54.1666666667%;
    }
    .medium-margin-collapse > .medium-14 {
        width: 58.3333333333%;
    }
    .medium-margin-collapse > .medium-15 {
        width: 62.5%;
    }
    .medium-margin-collapse > .medium-16 {
        width: 66.6666666667%;
    }
    .medium-margin-collapse > .medium-17 {
        width: 70.8333333333%;
    }
    .medium-margin-collapse > .medium-18 {
        width: 75%;
    }
    .medium-margin-collapse > .medium-19 {
        width: 79.1666666667%;
    }
    .medium-margin-collapse > .medium-20 {
        width: 83.3333333333%;
    }
    .medium-margin-collapse > .medium-21 {
        width: 87.5%;
    }
    .medium-margin-collapse > .medium-22 {
        width: 91.6666666667%;
    }
    .medium-margin-collapse > .medium-23 {
        width: 95.8333333333%;
    }
    .medium-margin-collapse > .medium-24 {
        width: 100%;
    }
}
@media print, screen and (min-width: 40em) {
    .medium-margin-collapse > .large-1 {
        width: 4.1666666667%;
    }
    .medium-margin-collapse > .large-2 {
        width: 8.3333333333%;
    }
    .medium-margin-collapse > .large-3 {
        width: 12.5%;
    }
    .medium-margin-collapse > .large-4 {
        width: 16.6666666667%;
    }
    .medium-margin-collapse > .large-5 {
        width: 20.8333333333%;
    }
    .medium-margin-collapse > .large-6 {
        width: 25%;
    }
    .medium-margin-collapse > .large-7 {
        width: 29.1666666667%;
    }
    .medium-margin-collapse > .large-8 {
        width: 33.3333333333%;
    }
    .medium-margin-collapse > .large-9 {
        width: 37.5%;
    }
    .medium-margin-collapse > .large-10 {
        width: 41.6666666667%;
    }
    .medium-margin-collapse > .large-11 {
        width: 45.8333333333%;
    }
    .medium-margin-collapse > .large-12 {
        width: 50%;
    }
    .medium-margin-collapse > .large-13 {
        width: 54.1666666667%;
    }
    .medium-margin-collapse > .large-14 {
        width: 58.3333333333%;
    }
    .medium-margin-collapse > .large-15 {
        width: 62.5%;
    }
    .medium-margin-collapse > .large-16 {
        width: 66.6666666667%;
    }
    .medium-margin-collapse > .large-17 {
        width: 70.8333333333%;
    }
    .medium-margin-collapse > .large-18 {
        width: 75%;
    }
    .medium-margin-collapse > .large-19 {
        width: 79.1666666667%;
    }
    .medium-margin-collapse > .large-20 {
        width: 83.3333333333%;
    }
    .medium-margin-collapse > .large-21 {
        width: 87.5%;
    }
    .medium-margin-collapse > .large-22 {
        width: 91.6666666667%;
    }
    .medium-margin-collapse > .large-23 {
        width: 95.8333333333%;
    }
    .medium-margin-collapse > .large-24 {
        width: 100%;
    }
}

@media print, screen and (min-width: 40em) {
    .medium-padding-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .medium-padding-collapse > .cell {
        padding-right: 0;
        padding-left: 0;
    }
}

@media print, screen and (min-width: 64em) {
    .large-margin-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .large-margin-collapse > .cell {
        margin-right: 0;
        margin-left: 0;
    }
}
@media print, screen and (min-width: 64em) {
    .large-margin-collapse > .small-1 {
        width: 4.1666666667%;
    }
    .large-margin-collapse > .small-2 {
        width: 8.3333333333%;
    }
    .large-margin-collapse > .small-3 {
        width: 12.5%;
    }
    .large-margin-collapse > .small-4 {
        width: 16.6666666667%;
    }
    .large-margin-collapse > .small-5 {
        width: 20.8333333333%;
    }
    .large-margin-collapse > .small-6 {
        width: 25%;
    }
    .large-margin-collapse > .small-7 {
        width: 29.1666666667%;
    }
    .large-margin-collapse > .small-8 {
        width: 33.3333333333%;
    }
    .large-margin-collapse > .small-9 {
        width: 37.5%;
    }
    .large-margin-collapse > .small-10 {
        width: 41.6666666667%;
    }
    .large-margin-collapse > .small-11 {
        width: 45.8333333333%;
    }
    .large-margin-collapse > .small-12 {
        width: 50%;
    }
    .large-margin-collapse > .small-13 {
        width: 54.1666666667%;
    }
    .large-margin-collapse > .small-14 {
        width: 58.3333333333%;
    }
    .large-margin-collapse > .small-15 {
        width: 62.5%;
    }
    .large-margin-collapse > .small-16 {
        width: 66.6666666667%;
    }
    .large-margin-collapse > .small-17 {
        width: 70.8333333333%;
    }
    .large-margin-collapse > .small-18 {
        width: 75%;
    }
    .large-margin-collapse > .small-19 {
        width: 79.1666666667%;
    }
    .large-margin-collapse > .small-20 {
        width: 83.3333333333%;
    }
    .large-margin-collapse > .small-21 {
        width: 87.5%;
    }
    .large-margin-collapse > .small-22 {
        width: 91.6666666667%;
    }
    .large-margin-collapse > .small-23 {
        width: 95.8333333333%;
    }
    .large-margin-collapse > .small-24 {
        width: 100%;
    }
}
@media print, screen and (min-width: 64em) {
    .large-margin-collapse > .medium-1 {
        width: 4.1666666667%;
    }
    .large-margin-collapse > .medium-2 {
        width: 8.3333333333%;
    }
    .large-margin-collapse > .medium-3 {
        width: 12.5%;
    }
    .large-margin-collapse > .medium-4 {
        width: 16.6666666667%;
    }
    .large-margin-collapse > .medium-5 {
        width: 20.8333333333%;
    }
    .large-margin-collapse > .medium-6 {
        width: 25%;
    }
    .large-margin-collapse > .medium-7 {
        width: 29.1666666667%;
    }
    .large-margin-collapse > .medium-8 {
        width: 33.3333333333%;
    }
    .large-margin-collapse > .medium-9 {
        width: 37.5%;
    }
    .large-margin-collapse > .medium-10 {
        width: 41.6666666667%;
    }
    .large-margin-collapse > .medium-11 {
        width: 45.8333333333%;
    }
    .large-margin-collapse > .medium-12 {
        width: 50%;
    }
    .large-margin-collapse > .medium-13 {
        width: 54.1666666667%;
    }
    .large-margin-collapse > .medium-14 {
        width: 58.3333333333%;
    }
    .large-margin-collapse > .medium-15 {
        width: 62.5%;
    }
    .large-margin-collapse > .medium-16 {
        width: 66.6666666667%;
    }
    .large-margin-collapse > .medium-17 {
        width: 70.8333333333%;
    }
    .large-margin-collapse > .medium-18 {
        width: 75%;
    }
    .large-margin-collapse > .medium-19 {
        width: 79.1666666667%;
    }
    .large-margin-collapse > .medium-20 {
        width: 83.3333333333%;
    }
    .large-margin-collapse > .medium-21 {
        width: 87.5%;
    }
    .large-margin-collapse > .medium-22 {
        width: 91.6666666667%;
    }
    .large-margin-collapse > .medium-23 {
        width: 95.8333333333%;
    }
    .large-margin-collapse > .medium-24 {
        width: 100%;
    }
}
@media print, screen and (min-width: 64em) {
    .large-margin-collapse > .large-1 {
        width: 4.1666666667%;
    }
    .large-margin-collapse > .large-2 {
        width: 8.3333333333%;
    }
    .large-margin-collapse > .large-3 {
        width: 12.5%;
    }
    .large-margin-collapse > .large-4 {
        width: 16.6666666667%;
    }
    .large-margin-collapse > .large-5 {
        width: 20.8333333333%;
    }
    .large-margin-collapse > .large-6 {
        width: 25%;
    }
    .large-margin-collapse > .large-7 {
        width: 29.1666666667%;
    }
    .large-margin-collapse > .large-8 {
        width: 33.3333333333%;
    }
    .large-margin-collapse > .large-9 {
        width: 37.5%;
    }
    .large-margin-collapse > .large-10 {
        width: 41.6666666667%;
    }
    .large-margin-collapse > .large-11 {
        width: 45.8333333333%;
    }
    .large-margin-collapse > .large-12 {
        width: 50%;
    }
    .large-margin-collapse > .large-13 {
        width: 54.1666666667%;
    }
    .large-margin-collapse > .large-14 {
        width: 58.3333333333%;
    }
    .large-margin-collapse > .large-15 {
        width: 62.5%;
    }
    .large-margin-collapse > .large-16 {
        width: 66.6666666667%;
    }
    .large-margin-collapse > .large-17 {
        width: 70.8333333333%;
    }
    .large-margin-collapse > .large-18 {
        width: 75%;
    }
    .large-margin-collapse > .large-19 {
        width: 79.1666666667%;
    }
    .large-margin-collapse > .large-20 {
        width: 83.3333333333%;
    }
    .large-margin-collapse > .large-21 {
        width: 87.5%;
    }
    .large-margin-collapse > .large-22 {
        width: 91.6666666667%;
    }
    .large-margin-collapse > .large-23 {
        width: 95.8333333333%;
    }
    .large-margin-collapse > .large-24 {
        width: 100%;
    }
}

@media print, screen and (min-width: 64em) {
    .large-padding-collapse {
        margin-right: 0;
        margin-left: 0;
    }
    .large-padding-collapse > .cell {
        padding-right: 0;
        padding-left: 0;
    }
}

.small-offset-0 {
    margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
    margin-left: calc(0% + 0.625rem);
}

.small-offset-1 {
    margin-left: 4.1666666667%;
}

.grid-margin-x > .small-offset-1 {
    margin-left: calc(4.1666666667% + 0.625rem);
}

.small-offset-2 {
    margin-left: 8.3333333333%;
}

.grid-margin-x > .small-offset-2 {
    margin-left: calc(8.3333333333% + 0.625rem);
}

.small-offset-3 {
    margin-left: 12.5%;
}

.grid-margin-x > .small-offset-3 {
    margin-left: calc(12.5% + 0.625rem);
}

.small-offset-4 {
    margin-left: 16.6666666667%;
}

.grid-margin-x > .small-offset-4 {
    margin-left: calc(16.6666666667% + 0.625rem);
}

.small-offset-5 {
    margin-left: 20.8333333333%;
}

.grid-margin-x > .small-offset-5 {
    margin-left: calc(20.8333333333% + 0.625rem);
}

.small-offset-6 {
    margin-left: 25%;
}

.grid-margin-x > .small-offset-6 {
    margin-left: calc(25% + 0.625rem);
}

.small-offset-7 {
    margin-left: 29.1666666667%;
}

.grid-margin-x > .small-offset-7 {
    margin-left: calc(29.1666666667% + 0.625rem);
}

.small-offset-8 {
    margin-left: 33.3333333333%;
}

.grid-margin-x > .small-offset-8 {
    margin-left: calc(33.3333333333% + 0.625rem);
}

.small-offset-9 {
    margin-left: 37.5%;
}

.grid-margin-x > .small-offset-9 {
    margin-left: calc(37.5% + 0.625rem);
}

.small-offset-10 {
    margin-left: 41.6666666667%;
}

.grid-margin-x > .small-offset-10 {
    margin-left: calc(41.6666666667% + 0.625rem);
}

.small-offset-11 {
    margin-left: 45.8333333333%;
}

.grid-margin-x > .small-offset-11 {
    margin-left: calc(45.8333333333% + 0.625rem);
}

.small-offset-12 {
    margin-left: 50%;
}

.grid-margin-x > .small-offset-12 {
    margin-left: calc(50% + 0.625rem);
}

.small-offset-13 {
    margin-left: 54.1666666667%;
}

.grid-margin-x > .small-offset-13 {
    margin-left: calc(54.1666666667% + 0.625rem);
}

.small-offset-14 {
    margin-left: 58.3333333333%;
}

.grid-margin-x > .small-offset-14 {
    margin-left: calc(58.3333333333% + 0.625rem);
}

.small-offset-15 {
    margin-left: 62.5%;
}

.grid-margin-x > .small-offset-15 {
    margin-left: calc(62.5% + 0.625rem);
}

.small-offset-16 {
    margin-left: 66.6666666667%;
}

.grid-margin-x > .small-offset-16 {
    margin-left: calc(66.6666666667% + 0.625rem);
}

.small-offset-17 {
    margin-left: 70.8333333333%;
}

.grid-margin-x > .small-offset-17 {
    margin-left: calc(70.8333333333% + 0.625rem);
}

.small-offset-18 {
    margin-left: 75%;
}

.grid-margin-x > .small-offset-18 {
    margin-left: calc(75% + 0.625rem);
}

.small-offset-19 {
    margin-left: 79.1666666667%;
}

.grid-margin-x > .small-offset-19 {
    margin-left: calc(79.1666666667% + 0.625rem);
}

.small-offset-20 {
    margin-left: 83.3333333333%;
}

.grid-margin-x > .small-offset-20 {
    margin-left: calc(83.3333333333% + 0.625rem);
}

.small-offset-21 {
    margin-left: 87.5%;
}

.grid-margin-x > .small-offset-21 {
    margin-left: calc(87.5% + 0.625rem);
}

.small-offset-22 {
    margin-left: 91.6666666667%;
}

.grid-margin-x > .small-offset-22 {
    margin-left: calc(91.6666666667% + 0.625rem);
}

.small-offset-23 {
    margin-left: 95.8333333333%;
}

.grid-margin-x > .small-offset-23 {
    margin-left: calc(95.8333333333% + 0.625rem);
}

@media print, screen and (min-width: 40em) {
    .medium-offset-0 {
        margin-left: 0%;
    }

    .grid-margin-x > .medium-offset-0 {
        margin-left: calc(0% + 0.9375rem);
    }

    .medium-offset-1 {
        margin-left: 4.1666666667%;
    }

    .grid-margin-x > .medium-offset-1 {
        margin-left: calc(4.1666666667% + 0.9375rem);
    }

    .medium-offset-2 {
        margin-left: 8.3333333333%;
    }

    .grid-margin-x > .medium-offset-2 {
        margin-left: calc(8.3333333333% + 0.9375rem);
    }

    .medium-offset-3 {
        margin-left: 12.5%;
    }

    .grid-margin-x > .medium-offset-3 {
        margin-left: calc(12.5% + 0.9375rem);
    }

    .medium-offset-4 {
        margin-left: 16.6666666667%;
    }

    .grid-margin-x > .medium-offset-4 {
        margin-left: calc(16.6666666667% + 0.9375rem);
    }

    .medium-offset-5 {
        margin-left: 20.8333333333%;
    }

    .grid-margin-x > .medium-offset-5 {
        margin-left: calc(20.8333333333% + 0.9375rem);
    }

    .medium-offset-6 {
        margin-left: 25%;
    }

    .grid-margin-x > .medium-offset-6 {
        margin-left: calc(25% + 0.9375rem);
    }

    .medium-offset-7 {
        margin-left: 29.1666666667%;
    }

    .grid-margin-x > .medium-offset-7 {
        margin-left: calc(29.1666666667% + 0.9375rem);
    }

    .medium-offset-8 {
        margin-left: 33.3333333333%;
    }

    .grid-margin-x > .medium-offset-8 {
        margin-left: calc(33.3333333333% + 0.9375rem);
    }

    .medium-offset-9 {
        margin-left: 37.5%;
    }

    .grid-margin-x > .medium-offset-9 {
        margin-left: calc(37.5% + 0.9375rem);
    }

    .medium-offset-10 {
        margin-left: 41.6666666667%;
    }

    .grid-margin-x > .medium-offset-10 {
        margin-left: calc(41.6666666667% + 0.9375rem);
    }

    .medium-offset-11 {
        margin-left: 45.8333333333%;
    }

    .grid-margin-x > .medium-offset-11 {
        margin-left: calc(45.8333333333% + 0.9375rem);
    }

    .medium-offset-12 {
        margin-left: 50%;
    }

    .grid-margin-x > .medium-offset-12 {
        margin-left: calc(50% + 0.9375rem);
    }

    .medium-offset-13 {
        margin-left: 54.1666666667%;
    }

    .grid-margin-x > .medium-offset-13 {
        margin-left: calc(54.1666666667% + 0.9375rem);
    }

    .medium-offset-14 {
        margin-left: 58.3333333333%;
    }

    .grid-margin-x > .medium-offset-14 {
        margin-left: calc(58.3333333333% + 0.9375rem);
    }

    .medium-offset-15 {
        margin-left: 62.5%;
    }

    .grid-margin-x > .medium-offset-15 {
        margin-left: calc(62.5% + 0.9375rem);
    }

    .medium-offset-16 {
        margin-left: 66.6666666667%;
    }

    .grid-margin-x > .medium-offset-16 {
        margin-left: calc(66.6666666667% + 0.9375rem);
    }

    .medium-offset-17 {
        margin-left: 70.8333333333%;
    }

    .grid-margin-x > .medium-offset-17 {
        margin-left: calc(70.8333333333% + 0.9375rem);
    }

    .medium-offset-18 {
        margin-left: 75%;
    }

    .grid-margin-x > .medium-offset-18 {
        margin-left: calc(75% + 0.9375rem);
    }

    .medium-offset-19 {
        margin-left: 79.1666666667%;
    }

    .grid-margin-x > .medium-offset-19 {
        margin-left: calc(79.1666666667% + 0.9375rem);
    }

    .medium-offset-20 {
        margin-left: 83.3333333333%;
    }

    .grid-margin-x > .medium-offset-20 {
        margin-left: calc(83.3333333333% + 0.9375rem);
    }

    .medium-offset-21 {
        margin-left: 87.5%;
    }

    .grid-margin-x > .medium-offset-21 {
        margin-left: calc(87.5% + 0.9375rem);
    }

    .medium-offset-22 {
        margin-left: 91.6666666667%;
    }

    .grid-margin-x > .medium-offset-22 {
        margin-left: calc(91.6666666667% + 0.9375rem);
    }

    .medium-offset-23 {
        margin-left: 95.8333333333%;
    }

    .grid-margin-x > .medium-offset-23 {
        margin-left: calc(95.8333333333% + 0.9375rem);
    }
}
@media print, screen and (min-width: 64em) {
    .large-offset-0 {
        margin-left: 0%;
    }

    .grid-margin-x > .large-offset-0 {
        margin-left: calc(0% + 0.9375rem);
    }

    .large-offset-1 {
        margin-left: 4.1666666667%;
    }

    .grid-margin-x > .large-offset-1 {
        margin-left: calc(4.1666666667% + 0.9375rem);
    }

    .large-offset-2 {
        margin-left: 8.3333333333%;
    }

    .grid-margin-x > .large-offset-2 {
        margin-left: calc(8.3333333333% + 0.9375rem);
    }

    .large-offset-3 {
        margin-left: 12.5%;
    }

    .grid-margin-x > .large-offset-3 {
        margin-left: calc(12.5% + 0.9375rem);
    }

    .large-offset-4 {
        margin-left: 16.6666666667%;
    }

    .grid-margin-x > .large-offset-4 {
        margin-left: calc(16.6666666667% + 0.9375rem);
    }

    .large-offset-5 {
        margin-left: 20.8333333333%;
    }

    .grid-margin-x > .large-offset-5 {
        margin-left: calc(20.8333333333% + 0.9375rem);
    }

    .large-offset-6 {
        margin-left: 25%;
    }

    .grid-margin-x > .large-offset-6 {
        margin-left: calc(25% + 0.9375rem);
    }

    .large-offset-7 {
        margin-left: 29.1666666667%;
    }

    .grid-margin-x > .large-offset-7 {
        margin-left: calc(29.1666666667% + 0.9375rem);
    }

    .large-offset-8 {
        margin-left: 33.3333333333%;
    }

    .grid-margin-x > .large-offset-8 {
        margin-left: calc(33.3333333333% + 0.9375rem);
    }

    .large-offset-9 {
        margin-left: 37.5%;
    }

    .grid-margin-x > .large-offset-9 {
        margin-left: calc(37.5% + 0.9375rem);
    }

    .large-offset-10 {
        margin-left: 41.6666666667%;
    }

    .grid-margin-x > .large-offset-10 {
        margin-left: calc(41.6666666667% + 0.9375rem);
    }

    .large-offset-11 {
        margin-left: 45.8333333333%;
    }

    .grid-margin-x > .large-offset-11 {
        margin-left: calc(45.8333333333% + 0.9375rem);
    }

    .large-offset-12 {
        margin-left: 50%;
    }

    .grid-margin-x > .large-offset-12 {
        margin-left: calc(50% + 0.9375rem);
    }

    .large-offset-13 {
        margin-left: 54.1666666667%;
    }

    .grid-margin-x > .large-offset-13 {
        margin-left: calc(54.1666666667% + 0.9375rem);
    }

    .large-offset-14 {
        margin-left: 58.3333333333%;
    }

    .grid-margin-x > .large-offset-14 {
        margin-left: calc(58.3333333333% + 0.9375rem);
    }

    .large-offset-15 {
        margin-left: 62.5%;
    }

    .grid-margin-x > .large-offset-15 {
        margin-left: calc(62.5% + 0.9375rem);
    }

    .large-offset-16 {
        margin-left: 66.6666666667%;
    }

    .grid-margin-x > .large-offset-16 {
        margin-left: calc(66.6666666667% + 0.9375rem);
    }

    .large-offset-17 {
        margin-left: 70.8333333333%;
    }

    .grid-margin-x > .large-offset-17 {
        margin-left: calc(70.8333333333% + 0.9375rem);
    }

    .large-offset-18 {
        margin-left: 75%;
    }

    .grid-margin-x > .large-offset-18 {
        margin-left: calc(75% + 0.9375rem);
    }

    .large-offset-19 {
        margin-left: 79.1666666667%;
    }

    .grid-margin-x > .large-offset-19 {
        margin-left: calc(79.1666666667% + 0.9375rem);
    }

    .large-offset-20 {
        margin-left: 83.3333333333%;
    }

    .grid-margin-x > .large-offset-20 {
        margin-left: calc(83.3333333333% + 0.9375rem);
    }

    .large-offset-21 {
        margin-left: 87.5%;
    }

    .grid-margin-x > .large-offset-21 {
        margin-left: calc(87.5% + 0.9375rem);
    }

    .large-offset-22 {
        margin-left: 91.6666666667%;
    }

    .grid-margin-x > .large-offset-22 {
        margin-left: calc(91.6666666667% + 0.9375rem);
    }

    .large-offset-23 {
        margin-left: 95.8333333333%;
    }

    .grid-margin-x > .large-offset-23 {
        margin-left: calc(95.8333333333% + 0.9375rem);
    }
}
.grid-y {
    display: flex;
    flex-flow: column nowrap;
}
.grid-y > .cell {
    height: auto;
    max-height: none;
}
.grid-y > .auto {
    height: auto;
}
.grid-y > .shrink {
    height: auto;
}
.grid-y > .small-shrink,
.grid-y > .small-full,
.grid-y > .small-1,
.grid-y > .small-2,
.grid-y > .small-3,
.grid-y > .small-4,
.grid-y > .small-5,
.grid-y > .small-6,
.grid-y > .small-7,
.grid-y > .small-8,
.grid-y > .small-9,
.grid-y > .small-10,
.grid-y > .small-11,
.grid-y > .small-12,
.grid-y > .small-13,
.grid-y > .small-14,
.grid-y > .small-15,
.grid-y > .small-16,
.grid-y > .small-17,
.grid-y > .small-18,
.grid-y > .small-19,
.grid-y > .small-20,
.grid-y > .small-21,
.grid-y > .small-22,
.grid-y > .small-23,
.grid-y > .small-24 {
    flex-basis: auto;
}
@media print, screen and (min-width: 40em) {
    .grid-y > .medium-shrink,
    .grid-y > .medium-full,
    .grid-y > .medium-1,
    .grid-y > .medium-2,
    .grid-y > .medium-3,
    .grid-y > .medium-4,
    .grid-y > .medium-5,
    .grid-y > .medium-6,
    .grid-y > .medium-7,
    .grid-y > .medium-8,
    .grid-y > .medium-9,
    .grid-y > .medium-10,
    .grid-y > .medium-11,
    .grid-y > .medium-12,
    .grid-y > .medium-13,
    .grid-y > .medium-14,
    .grid-y > .medium-15,
    .grid-y > .medium-16,
    .grid-y > .medium-17,
    .grid-y > .medium-18,
    .grid-y > .medium-19,
    .grid-y > .medium-20,
    .grid-y > .medium-21,
    .grid-y > .medium-22,
    .grid-y > .medium-23,
    .grid-y > .medium-24 {
        flex-basis: auto;
    }
}
@media print, screen and (min-width: 64em) {
    .grid-y > .large-shrink,
    .grid-y > .large-full,
    .grid-y > .large-1,
    .grid-y > .large-2,
    .grid-y > .large-3,
    .grid-y > .large-4,
    .grid-y > .large-5,
    .grid-y > .large-6,
    .grid-y > .large-7,
    .grid-y > .large-8,
    .grid-y > .large-9,
    .grid-y > .large-10,
    .grid-y > .large-11,
    .grid-y > .large-12,
    .grid-y > .large-13,
    .grid-y > .large-14,
    .grid-y > .large-15,
    .grid-y > .large-16,
    .grid-y > .large-17,
    .grid-y > .large-18,
    .grid-y > .large-19,
    .grid-y > .large-20,
    .grid-y > .large-21,
    .grid-y > .large-22,
    .grid-y > .large-23,
    .grid-y > .large-24 {
        flex-basis: auto;
    }
}
.grid-y > .small-24,
.grid-y > .small-23,
.grid-y > .small-22,
.grid-y > .small-21,
.grid-y > .small-20,
.grid-y > .small-19,
.grid-y > .small-18,
.grid-y > .small-17,
.grid-y > .small-16,
.grid-y > .small-15,
.grid-y > .small-14,
.grid-y > .small-13,
.grid-y > .small-12,
.grid-y > .small-11,
.grid-y > .small-10,
.grid-y > .small-9,
.grid-y > .small-8,
.grid-y > .small-7,
.grid-y > .small-6,
.grid-y > .small-5,
.grid-y > .small-4,
.grid-y > .small-3,
.grid-y > .small-2,
.grid-y > .small-1 {
    flex: 0 0 auto;
}
.grid-y > .small-1 {
    height: 4.1666666667%;
}
.grid-y > .small-2 {
    height: 8.3333333333%;
}
.grid-y > .small-3 {
    height: 12.5%;
}
.grid-y > .small-4 {
    height: 16.6666666667%;
}
.grid-y > .small-5 {
    height: 20.8333333333%;
}
.grid-y > .small-6 {
    height: 25%;
}
.grid-y > .small-7 {
    height: 29.1666666667%;
}
.grid-y > .small-8 {
    height: 33.3333333333%;
}
.grid-y > .small-9 {
    height: 37.5%;
}
.grid-y > .small-10 {
    height: 41.6666666667%;
}
.grid-y > .small-11 {
    height: 45.8333333333%;
}
.grid-y > .small-12 {
    height: 50%;
}
.grid-y > .small-13 {
    height: 54.1666666667%;
}
.grid-y > .small-14 {
    height: 58.3333333333%;
}
.grid-y > .small-15 {
    height: 62.5%;
}
.grid-y > .small-16 {
    height: 66.6666666667%;
}
.grid-y > .small-17 {
    height: 70.8333333333%;
}
.grid-y > .small-18 {
    height: 75%;
}
.grid-y > .small-19 {
    height: 79.1666666667%;
}
.grid-y > .small-20 {
    height: 83.3333333333%;
}
.grid-y > .small-21 {
    height: 87.5%;
}
.grid-y > .small-22 {
    height: 91.6666666667%;
}
.grid-y > .small-23 {
    height: 95.8333333333%;
}
.grid-y > .small-24 {
    height: 100%;
}
@media print, screen and (min-width: 40em) {
    .grid-y > .medium-24,
    .grid-y > .medium-23,
    .grid-y > .medium-22,
    .grid-y > .medium-21,
    .grid-y > .medium-20,
    .grid-y > .medium-19,
    .grid-y > .medium-18,
    .grid-y > .medium-17,
    .grid-y > .medium-16,
    .grid-y > .medium-15,
    .grid-y > .medium-14,
    .grid-y > .medium-13,
    .grid-y > .medium-12,
    .grid-y > .medium-11,
    .grid-y > .medium-10,
    .grid-y > .medium-9,
    .grid-y > .medium-8,
    .grid-y > .medium-7,
    .grid-y > .medium-6,
    .grid-y > .medium-5,
    .grid-y > .medium-4,
    .grid-y > .medium-3,
    .grid-y > .medium-2,
    .grid-y > .medium-1,
    .grid-y > .medium-shrink {
        flex: 0 0 auto;
    }
    .grid-y > .medium-shrink {
        height: auto;
    }
    .grid-y > .medium-1 {
        height: 4.1666666667%;
    }
    .grid-y > .medium-2 {
        height: 8.3333333333%;
    }
    .grid-y > .medium-3 {
        height: 12.5%;
    }
    .grid-y > .medium-4 {
        height: 16.6666666667%;
    }
    .grid-y > .medium-5 {
        height: 20.8333333333%;
    }
    .grid-y > .medium-6 {
        height: 25%;
    }
    .grid-y > .medium-7 {
        height: 29.1666666667%;
    }
    .grid-y > .medium-8 {
        height: 33.3333333333%;
    }
    .grid-y > .medium-9 {
        height: 37.5%;
    }
    .grid-y > .medium-10 {
        height: 41.6666666667%;
    }
    .grid-y > .medium-11 {
        height: 45.8333333333%;
    }
    .grid-y > .medium-12 {
        height: 50%;
    }
    .grid-y > .medium-13 {
        height: 54.1666666667%;
    }
    .grid-y > .medium-14 {
        height: 58.3333333333%;
    }
    .grid-y > .medium-15 {
        height: 62.5%;
    }
    .grid-y > .medium-16 {
        height: 66.6666666667%;
    }
    .grid-y > .medium-17 {
        height: 70.8333333333%;
    }
    .grid-y > .medium-18 {
        height: 75%;
    }
    .grid-y > .medium-19 {
        height: 79.1666666667%;
    }
    .grid-y > .medium-20 {
        height: 83.3333333333%;
    }
    .grid-y > .medium-21 {
        height: 87.5%;
    }
    .grid-y > .medium-22 {
        height: 91.6666666667%;
    }
    .grid-y > .medium-23 {
        height: 95.8333333333%;
    }
    .grid-y > .medium-24 {
        height: 100%;
    }
}
@media print, screen and (min-width: 64em) {
    .grid-y > .large-24,
    .grid-y > .large-23,
    .grid-y > .large-22,
    .grid-y > .large-21,
    .grid-y > .large-20,
    .grid-y > .large-19,
    .grid-y > .large-18,
    .grid-y > .large-17,
    .grid-y > .large-16,
    .grid-y > .large-15,
    .grid-y > .large-14,
    .grid-y > .large-13,
    .grid-y > .large-12,
    .grid-y > .large-11,
    .grid-y > .large-10,
    .grid-y > .large-9,
    .grid-y > .large-8,
    .grid-y > .large-7,
    .grid-y > .large-6,
    .grid-y > .large-5,
    .grid-y > .large-4,
    .grid-y > .large-3,
    .grid-y > .large-2,
    .grid-y > .large-1,
    .grid-y > .large-shrink {
        flex: 0 0 auto;
    }
    .grid-y > .large-shrink {
        height: auto;
    }
    .grid-y > .large-1 {
        height: 4.1666666667%;
    }
    .grid-y > .large-2 {
        height: 8.3333333333%;
    }
    .grid-y > .large-3 {
        height: 12.5%;
    }
    .grid-y > .large-4 {
        height: 16.6666666667%;
    }
    .grid-y > .large-5 {
        height: 20.8333333333%;
    }
    .grid-y > .large-6 {
        height: 25%;
    }
    .grid-y > .large-7 {
        height: 29.1666666667%;
    }
    .grid-y > .large-8 {
        height: 33.3333333333%;
    }
    .grid-y > .large-9 {
        height: 37.5%;
    }
    .grid-y > .large-10 {
        height: 41.6666666667%;
    }
    .grid-y > .large-11 {
        height: 45.8333333333%;
    }
    .grid-y > .large-12 {
        height: 50%;
    }
    .grid-y > .large-13 {
        height: 54.1666666667%;
    }
    .grid-y > .large-14 {
        height: 58.3333333333%;
    }
    .grid-y > .large-15 {
        height: 62.5%;
    }
    .grid-y > .large-16 {
        height: 66.6666666667%;
    }
    .grid-y > .large-17 {
        height: 70.8333333333%;
    }
    .grid-y > .large-18 {
        height: 75%;
    }
    .grid-y > .large-19 {
        height: 79.1666666667%;
    }
    .grid-y > .large-20 {
        height: 83.3333333333%;
    }
    .grid-y > .large-21 {
        height: 87.5%;
    }
    .grid-y > .large-22 {
        height: 91.6666666667%;
    }
    .grid-y > .large-23 {
        height: 95.8333333333%;
    }
    .grid-y > .large-24 {
        height: 100%;
    }
}

.grid-padding-y .grid-padding-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-padding-y .grid-padding-y {
        margin-top: -0.9375rem;
        margin-bottom: -0.9375rem;
    }
}
.grid-padding-y > .cell {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-padding-y > .cell {
        padding-top: 0.9375rem;
        padding-bottom: 0.9375rem;
    }
}

.grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-margin-y {
        margin-top: -0.9375rem;
        margin-bottom: -0.9375rem;
    }
}
.grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
        height: calc(100% - 1.876rem);
        margin-top: 0.9375rem;
        margin-bottom: 0.9375rem;
    }
}
.grid-margin-y > .auto {
    height: auto;
}
.grid-margin-y > .shrink {
    height: auto;
}
.grid-margin-y > .small-1 {
    height: calc(4.1666666667% - 1.25rem);
}
.grid-margin-y > .small-2 {
    height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-3 {
    height: calc(12.5% - 1.25rem);
}
.grid-margin-y > .small-4 {
    height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-5 {
    height: calc(20.8333333333% - 1.25rem);
}
.grid-margin-y > .small-6 {
    height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-7 {
    height: calc(29.1666666667% - 1.25rem);
}
.grid-margin-y > .small-8 {
    height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-9 {
    height: calc(37.5% - 1.25rem);
}
.grid-margin-y > .small-10 {
    height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-11 {
    height: calc(45.8333333333% - 1.25rem);
}
.grid-margin-y > .small-12 {
    height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-13 {
    height: calc(54.1666666667% - 1.25rem);
}
.grid-margin-y > .small-14 {
    height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-15 {
    height: calc(62.5% - 1.25rem);
}
.grid-margin-y > .small-16 {
    height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-17 {
    height: calc(70.8333333333% - 1.25rem);
}
.grid-margin-y > .small-18 {
    height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-19 {
    height: calc(79.1666666667% - 1.25rem);
}
.grid-margin-y > .small-20 {
    height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-21 {
    height: calc(87.5% - 1.25rem);
}
.grid-margin-y > .small-22 {
    height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-23 {
    height: calc(95.8333333333% - 1.25rem);
}
.grid-margin-y > .small-24 {
    height: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
        height: auto;
    }
    .grid-margin-y > .shrink {
        height: auto;
    }
    .grid-margin-y > .small-1 {
        height: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-2 {
        height: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-3 {
        height: calc(12.5% - 1.876rem);
    }
    .grid-margin-y > .small-4 {
        height: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-5 {
        height: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-6 {
        height: calc(25% - 1.876rem);
    }
    .grid-margin-y > .small-7 {
        height: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-8 {
        height: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-9 {
        height: calc(37.5% - 1.876rem);
    }
    .grid-margin-y > .small-10 {
        height: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-11 {
        height: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-12 {
        height: calc(50% - 1.876rem);
    }
    .grid-margin-y > .small-13 {
        height: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-14 {
        height: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-15 {
        height: calc(62.5% - 1.876rem);
    }
    .grid-margin-y > .small-16 {
        height: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-17 {
        height: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-18 {
        height: calc(75% - 1.876rem);
    }
    .grid-margin-y > .small-19 {
        height: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-20 {
        height: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-21 {
        height: calc(87.5% - 1.876rem);
    }
    .grid-margin-y > .small-22 {
        height: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-23 {
        height: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-24 {
        height: calc(100% - 1.876rem);
    }
    .grid-margin-y > .medium-auto {
        height: auto;
    }
    .grid-margin-y > .medium-shrink {
        height: auto;
    }
    .grid-margin-y > .medium-1 {
        height: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-2 {
        height: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-3 {
        height: calc(12.5% - 1.876rem);
    }
    .grid-margin-y > .medium-4 {
        height: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-5 {
        height: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-6 {
        height: calc(25% - 1.876rem);
    }
    .grid-margin-y > .medium-7 {
        height: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-8 {
        height: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-9 {
        height: calc(37.5% - 1.876rem);
    }
    .grid-margin-y > .medium-10 {
        height: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-11 {
        height: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-12 {
        height: calc(50% - 1.876rem);
    }
    .grid-margin-y > .medium-13 {
        height: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-14 {
        height: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-15 {
        height: calc(62.5% - 1.876rem);
    }
    .grid-margin-y > .medium-16 {
        height: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-17 {
        height: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-18 {
        height: calc(75% - 1.876rem);
    }
    .grid-margin-y > .medium-19 {
        height: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-20 {
        height: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-21 {
        height: calc(87.5% - 1.876rem);
    }
    .grid-margin-y > .medium-22 {
        height: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-23 {
        height: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-24 {
        height: calc(100% - 1.876rem);
    }
}
@media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
        height: auto;
    }
    .grid-margin-y > .large-shrink {
        height: auto;
    }
    .grid-margin-y > .large-1 {
        height: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-2 {
        height: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-3 {
        height: calc(12.5% - 1.876rem);
    }
    .grid-margin-y > .large-4 {
        height: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-5 {
        height: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-6 {
        height: calc(25% - 1.876rem);
    }
    .grid-margin-y > .large-7 {
        height: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-8 {
        height: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-9 {
        height: calc(37.5% - 1.876rem);
    }
    .grid-margin-y > .large-10 {
        height: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-11 {
        height: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-12 {
        height: calc(50% - 1.876rem);
    }
    .grid-margin-y > .large-13 {
        height: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-14 {
        height: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-15 {
        height: calc(62.5% - 1.876rem);
    }
    .grid-margin-y > .large-16 {
        height: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-17 {
        height: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-18 {
        height: calc(75% - 1.876rem);
    }
    .grid-margin-y > .large-19 {
        height: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-20 {
        height: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-21 {
        height: calc(87.5% - 1.876rem);
    }
    .grid-margin-y > .large-22 {
        height: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-23 {
        height: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-24 {
        height: calc(100% - 1.876rem);
    }
}

.grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
}

.cell .grid-frame {
    width: 100%;
}

.cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
}
.cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
}

@media print, screen and (min-width: 40em) {
    .medium-grid-frame {
        overflow: hidden;
        position: relative;
        flex-wrap: nowrap;
        align-items: stretch;
        width: 100vw;
    }

    .cell .medium-grid-frame {
        width: 100%;
    }

    .medium-cell-block {
        overflow-x: auto;
        max-width: 100%;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .medium-cell-block-container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
    .medium-cell-block-container > .grid-x {
        max-height: 100%;
        flex-wrap: nowrap;
    }

    .medium-cell-block-y {
        overflow-y: auto;
        max-height: 100%;
        min-height: 100%;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}
@media print, screen and (min-width: 64em) {
    .large-grid-frame {
        overflow: hidden;
        position: relative;
        flex-wrap: nowrap;
        align-items: stretch;
        width: 100vw;
    }

    .cell .large-grid-frame {
        width: 100%;
    }

    .large-cell-block {
        overflow-x: auto;
        max-width: 100%;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .large-cell-block-container {
        display: flex;
        flex-direction: column;
        max-height: 100%;
    }
    .large-cell-block-container > .grid-x {
        max-height: 100%;
        flex-wrap: nowrap;
    }

    .large-cell-block-y {
        overflow-y: auto;
        max-height: 100%;
        min-height: 100%;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
}
.grid-y.grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
}
@media print, screen and (min-width: 40em) {
    .grid-y.medium-grid-frame {
        width: auto;
        overflow: hidden;
        position: relative;
        flex-wrap: nowrap;
        align-items: stretch;
        height: 100vh;
    }
}
@media print, screen and (min-width: 64em) {
    .grid-y.large-grid-frame {
        width: auto;
        overflow: hidden;
        position: relative;
        flex-wrap: nowrap;
        align-items: stretch;
        height: 100vh;
    }
}

.cell .grid-y.grid-frame {
    height: 100%;
}
@media print, screen and (min-width: 40em) {
    .cell .grid-y.medium-grid-frame {
        height: 100%;
    }
}
@media print, screen and (min-width: 64em) {
    .cell .grid-y.large-grid-frame {
        height: 100%;
    }
}

.grid-margin-y {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-margin-y {
        margin-top: -0.9375rem;
        margin-bottom: -0.9375rem;
    }
}
.grid-margin-y > .cell {
    height: calc(100% - 1.25rem);
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}
@media print, screen and (min-width: 40em) {
    .grid-margin-y > .cell {
        height: calc(100% - 1.876rem);
        margin-top: 0.9375rem;
        margin-bottom: 0.9375rem;
    }
}
.grid-margin-y > .auto {
    height: auto;
}
.grid-margin-y > .shrink {
    height: auto;
}
.grid-margin-y > .small-1 {
    height: calc(4.1666666667% - 1.25rem);
}
.grid-margin-y > .small-2 {
    height: calc(8.3333333333% - 1.25rem);
}
.grid-margin-y > .small-3 {
    height: calc(12.5% - 1.25rem);
}
.grid-margin-y > .small-4 {
    height: calc(16.6666666667% - 1.25rem);
}
.grid-margin-y > .small-5 {
    height: calc(20.8333333333% - 1.25rem);
}
.grid-margin-y > .small-6 {
    height: calc(25% - 1.25rem);
}
.grid-margin-y > .small-7 {
    height: calc(29.1666666667% - 1.25rem);
}
.grid-margin-y > .small-8 {
    height: calc(33.3333333333% - 1.25rem);
}
.grid-margin-y > .small-9 {
    height: calc(37.5% - 1.25rem);
}
.grid-margin-y > .small-10 {
    height: calc(41.6666666667% - 1.25rem);
}
.grid-margin-y > .small-11 {
    height: calc(45.8333333333% - 1.25rem);
}
.grid-margin-y > .small-12 {
    height: calc(50% - 1.25rem);
}
.grid-margin-y > .small-13 {
    height: calc(54.1666666667% - 1.25rem);
}
.grid-margin-y > .small-14 {
    height: calc(58.3333333333% - 1.25rem);
}
.grid-margin-y > .small-15 {
    height: calc(62.5% - 1.25rem);
}
.grid-margin-y > .small-16 {
    height: calc(66.6666666667% - 1.25rem);
}
.grid-margin-y > .small-17 {
    height: calc(70.8333333333% - 1.25rem);
}
.grid-margin-y > .small-18 {
    height: calc(75% - 1.25rem);
}
.grid-margin-y > .small-19 {
    height: calc(79.1666666667% - 1.25rem);
}
.grid-margin-y > .small-20 {
    height: calc(83.3333333333% - 1.25rem);
}
.grid-margin-y > .small-21 {
    height: calc(87.5% - 1.25rem);
}
.grid-margin-y > .small-22 {
    height: calc(91.6666666667% - 1.25rem);
}
.grid-margin-y > .small-23 {
    height: calc(95.8333333333% - 1.25rem);
}
.grid-margin-y > .small-24 {
    height: calc(100% - 1.25rem);
}
@media print, screen and (min-width: 40em) {
    .grid-margin-y > .auto {
        height: auto;
    }
    .grid-margin-y > .shrink {
        height: auto;
    }
    .grid-margin-y > .small-1 {
        height: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-2 {
        height: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-3 {
        height: calc(12.5% - 1.876rem);
    }
    .grid-margin-y > .small-4 {
        height: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-5 {
        height: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-6 {
        height: calc(25% - 1.876rem);
    }
    .grid-margin-y > .small-7 {
        height: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-8 {
        height: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-9 {
        height: calc(37.5% - 1.876rem);
    }
    .grid-margin-y > .small-10 {
        height: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-11 {
        height: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-12 {
        height: calc(50% - 1.876rem);
    }
    .grid-margin-y > .small-13 {
        height: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-14 {
        height: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-15 {
        height: calc(62.5% - 1.876rem);
    }
    .grid-margin-y > .small-16 {
        height: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-17 {
        height: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-18 {
        height: calc(75% - 1.876rem);
    }
    .grid-margin-y > .small-19 {
        height: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-y > .small-20 {
        height: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-y > .small-21 {
        height: calc(87.5% - 1.876rem);
    }
    .grid-margin-y > .small-22 {
        height: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-y > .small-23 {
        height: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-y > .small-24 {
        height: calc(100% - 1.876rem);
    }
    .grid-margin-y > .medium-auto {
        height: auto;
    }
    .grid-margin-y > .medium-shrink {
        height: auto;
    }
    .grid-margin-y > .medium-1 {
        height: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-2 {
        height: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-3 {
        height: calc(12.5% - 1.876rem);
    }
    .grid-margin-y > .medium-4 {
        height: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-5 {
        height: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-6 {
        height: calc(25% - 1.876rem);
    }
    .grid-margin-y > .medium-7 {
        height: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-8 {
        height: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-9 {
        height: calc(37.5% - 1.876rem);
    }
    .grid-margin-y > .medium-10 {
        height: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-11 {
        height: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-12 {
        height: calc(50% - 1.876rem);
    }
    .grid-margin-y > .medium-13 {
        height: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-14 {
        height: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-15 {
        height: calc(62.5% - 1.876rem);
    }
    .grid-margin-y > .medium-16 {
        height: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-17 {
        height: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-18 {
        height: calc(75% - 1.876rem);
    }
    .grid-margin-y > .medium-19 {
        height: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-20 {
        height: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-21 {
        height: calc(87.5% - 1.876rem);
    }
    .grid-margin-y > .medium-22 {
        height: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-y > .medium-23 {
        height: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-y > .medium-24 {
        height: calc(100% - 1.876rem);
    }
}
@media print, screen and (min-width: 64em) {
    .grid-margin-y > .large-auto {
        height: auto;
    }
    .grid-margin-y > .large-shrink {
        height: auto;
    }
    .grid-margin-y > .large-1 {
        height: calc(4.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-2 {
        height: calc(8.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-3 {
        height: calc(12.5% - 1.876rem);
    }
    .grid-margin-y > .large-4 {
        height: calc(16.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-5 {
        height: calc(20.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-6 {
        height: calc(25% - 1.876rem);
    }
    .grid-margin-y > .large-7 {
        height: calc(29.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-8 {
        height: calc(33.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-9 {
        height: calc(37.5% - 1.876rem);
    }
    .grid-margin-y > .large-10 {
        height: calc(41.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-11 {
        height: calc(45.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-12 {
        height: calc(50% - 1.876rem);
    }
    .grid-margin-y > .large-13 {
        height: calc(54.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-14 {
        height: calc(58.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-15 {
        height: calc(62.5% - 1.876rem);
    }
    .grid-margin-y > .large-16 {
        height: calc(66.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-17 {
        height: calc(70.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-18 {
        height: calc(75% - 1.876rem);
    }
    .grid-margin-y > .large-19 {
        height: calc(79.1666666667% - 1.876rem);
    }
    .grid-margin-y > .large-20 {
        height: calc(83.3333333333% - 1.876rem);
    }
    .grid-margin-y > .large-21 {
        height: calc(87.5% - 1.876rem);
    }
    .grid-margin-y > .large-22 {
        height: calc(91.6666666667% - 1.876rem);
    }
    .grid-margin-y > .large-23 {
        height: calc(95.8333333333% - 1.876rem);
    }
    .grid-margin-y > .large-24 {
        height: calc(100% - 1.876rem);
    }
}

.grid-frame.grid-margin-y {
    height: calc(100vh + 1.25rem);
}
@media print, screen and (min-width: 40em) {
    .grid-frame.grid-margin-y {
        height: calc(100vh + 1.8755rem);
    }
}
@media print, screen and (min-width: 64em) {
    .grid-frame.grid-margin-y {
        height: calc(100vh + 1.8755rem);
    }
}

@media print, screen and (min-width: 40em) {
    .grid-margin-y.medium-grid-frame {
        height: calc(100vh + 1.8755rem);
    }
}
@media print, screen and (min-width: 64em) {
    .grid-margin-y.large-grid-frame {
        height: calc(100vh + 1.8755rem);
    }
}

/*# sourceMappingURL=grid.css.map */
