.popup-content {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #07070729;
    border-radius: 8px;
    .o-modal {
        padding: 20px;
        > button {
            color: var(--black);
            position: absolute;
            right: 0px;
            top: 0px;
            cursor: pointer;
            height: 45px;
        }

        .button-group {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            button {
                margin-left: 10px;
            }
        }
    }
}
