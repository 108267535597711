@import '~assets/sass/colors';

.o-taskGroupCardShort {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border-radius: 0.5em;
    padding: 20px 26px 28px 15px;
    min-height: 96px;
    height: 100%;
    width: 100%;
    -webkit-transition: max-height 1s ease-out;
    -moz-transition: max-height 1s ease-out;
    transition: max-height 1s ease-out;
    position: relative;

    &__title {
        font-size: var(--font-size-xlarge);
        font-weight: 600;
        margin: 0px 0px 3px 0px;
        margin-bottom: 15px;
    }

    p {
        color: get-color(--text-primary);
        margin: 0;
    }

    &__tooltip {
        position: absolute;
        text-align: right;
        right: 10px;
        top: 10px;
    }
}
