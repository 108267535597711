@import '~assets/sass/colors';

.a-userAvatar {
    .MuiAvatar-root {
        display: flex;
        justify-content: center;
        font-size: 14px;
        color: get-color(--gray-800);
        font-weight: var(--font-weight-semibold);
        border: 1px solid get-color(--gray-800);
        margin: auto;
        background-color: #e8e8e8;
        cursor: default;
    }

    &__selected {
        .MuiAvatar-root,
        .MuiAvatar-circular,
        .MuiAvatar-colorDefault {
            color: get-color(--white-hsl) !important;
            background-color: get-color(--gray-800) !important;
        }
    }
}
