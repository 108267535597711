@import '~assets/sass/colors';

.o-projectSummary {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
    }

    &__projectCode {
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-tiny);
        margin-bottom: 4px;
    }

    &__projectDescription {
        margin: 0px;
    }

    &__projectDetailsContainer {
        margin-bottom: 20px;
        p {
            color: get-color(--text-primary);
        }

        h1 {
            margin: 0px 0px 5px 0px;
            color: get-color(--text-primary);
            font-size: var(--font-size-large);
        }
    }
}
