@import '~assets/sass/colors';

.o-taskFundingForm {
    &__readOnlyContainer {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &__readOnlyLabel {
        color: get-color(--text-primary);
        font-weight: 600;
        margin-bottom: 14px;
    }

    &__readOnlyContent {
        color: get-color(--text-secondary);
        margin: 0;

        p {
            margin: 0 0 10px 0;
        }
    }

    &__allocation {
        display: flex;
        flex-direction: column;
        padding-right: 20px;
    }

    &__overAllocated {
        color: get-color(--error-main);

        &-withIcon {
            display: flex;
            align-items: center;

            p {
                padding-left: 5px;
            }
        }
    }

    &__total {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr;
        align-items: center;

        p {
            margin: 5px 0;
            padding-right: 0.5em;
            font-weight: 600;
        }

        strong {
            padding-right: 1.5em;
        }

        .amount {
            text-align: right;
        }
    }

    &__dollar {
        justify-self: center;
        padding: 0;
    }

    &__fundingResources {
        display: flex;
        flex-direction: column;
    }

    &__hr {
        width: calc(100% + 30px);
        border-top: 1px solid get-color(--divider);
        border-bottom: none;
        margin-left: -15px;
    }
    margin-bottom: 170px;

    form {
        > * {
            margin-bottom: 15px;
        }
    }

    &__selectLabel {
        .MuiInputLabel-outlined,
        .MuiInputLabel-shrink {
            transform: translate(0, -20px) scale(1) !important;
        }
    }

    form {
        margin-top: 30px;
    }

    &.readonly {
        h2 {
            font-weight: 600;
            width: 80%;
        }

        form {
            margin-top: 15px;
            padding-left: 6px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            p {
                width: 100%;
            }

            > div {
                width: 100%;
            }
        }
    }
}

.m-fundingResourceItem {
    color: get-color(--text-primary);
    display: flex;
    flex-direction: column;

    b {
        padding-right: 5px;
    }

    p {
        margin: 10px 0;
    }

    &__label {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;

        p {
            font-weight: bold;
        }
    }

    &__input {
        padding-left: 47px;
    }

    &__closeIcon {
        .MuiIcon-root {
            font-size: 25px;
        }
    }

    &__overAllocated {
        .MuiInputLabel-formControl,
        p {
            color: get-color(--error-main);
        }

        .MuiInputBase-root,
        .MuiInputBase-input,
        .MuiOutlinedInput-root,
        .MuiOutlinedInput-input,
        .MuiInputBase-formControl,
        .PrivateNotchedOutline-root-1,
        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid get-color(--error-main);
        }
    }
}
