@import '~assets/sass/colors';

.o-taskGroupForm {
    &__title {
        width: 90%;
    }

    form {
        > * {
            margin-bottom: 15px;
        }
    }

    &__hr {
        position: fixed;
        bottom: 80px;
        right: 0;
        width: 400px;
        border-top: 2px solid #00000029;
    }

    &__colorSpan {
        font-size: 16px;
        color: get-color(--text-primary);
    }

    &__buttons {
        position: fixed;
        bottom: 20px;
        right: 30px;
        display: flex;
        justify-content: flex-end;

        > button:first-child {
            margin-right: 20px;
        }
    }

    form {
        margin-top: 30px;
    }

    &__colorSelectorContainer {
        margin-top: 8px;
        width: fit-content;
    }
}

.o-offcanvas.--closed {
    .o-taskGroupForm__buttons,
    .o-taskGroupForm__hr {
        display: none;
    }
}

[role='tooltip'].popup-content {
    width: auto;
}

.o-randomcolorcontainer__tooltip-content {
    width: auto;
    border-color: get-color(--divider);
    box-shadow: 0px 1px 1px #48484829;
    ul {
        padding: 0px 10px;
        display: grid;
        grid-gap: 10px 10px;
        grid-template-columns: repeat(3, 20px);
        grid-template-rows: repeat(2, 20px);
    }
}

.o-randomcolorcontainer__tooltip {
    &-arrow {
        display: block;
        margin-top: 2px;
        margin-right: 4px;
        stroke-width: 4px;
        stroke: #cdcdcd;
    }
}
