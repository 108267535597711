.Milestone {
    &__container {
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        width: 180px;

        &__milestone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 100%;
            background: #ccc;
            white-space: nowrap;
        }
        & * > .fo_wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            white-space: nowrap;
            position: relative;

            &__textwrapper {
                display: flex;
                flex-direction: row;
                background: rgba(255, 255, 255, 0.29);
                border-radius: 20px;
            }
        }

        & * > .fo_wrapper-rounded {
            display: flex;
            flex-direction: column;
            justify-content: center;
            white-space: nowrap;

            &__textwrapper {
                display: flex;
                flex-direction: row;
                background: rgba(255, 255, 255, 0.9);
            }
        }
    }
    &__container:hover {
        stroke-width: 4px;
        stroke: rgba(0, 0, 0, 0.1);
    }
}
