@import '~assets/sass/colors';

.o-planCard {
    border: 1px solid get-color(--divider);
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    position: relative;
    margin: 0 0 30px 0;
    background-color: get-color(--white-hsl);

    &.-archived {
        background-color: #f5f5f5;
        .o-planCard__planCode {
            color: get-color(--text-secondary);
            &:hover {
                color: get-color(--text-secondary);
            }
        }
    }

    &__cardContainer {
        min-height: 100%;
    }

    &__planInformation {
        padding: 20px 16px;
        &:first-child {
            flex-grow: 5;
        }
        &:last-child {
            height: 80px;
        }
    }

    &__planName {
        font-size: var(--font-size-xlarge);
        font-weight: var(--font-weight-semibold);
        color: get-color(--text-primary);
        margin: 0;
    }

    &__projectButton {
        margin: 9px 16px;
        font-size: var(--font-size-normal);
        font-weight: var(--font-weight-semibold);
        color: get-color(--secondary-main);
        &:hover {
            color: get-color(--primary-main);
        }
    }

    &__planCode {
        color: var(--text-primary);
        font-weight: var(--font-weight-semibold);
        font-size: var(--font-size-tiny);
        margin: 0;
    }

    &__description {
        margin: 0;
        color: get-color(--text-secondary);
        font-size: var(--font-size-normal);
    }

    &__tooltip {
        position: absolute;
        right: 16px;
        top: 15px;
    }

    &__teamTitle {
        font-size: 16px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--text-primary);
        margin-right: 10px;
    }

    &__teamContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__hr {
        width: calc(100%);
        border-top: 1px solid get-color(--divider);
        border-bottom: none;
        margin: 0;
    }

    &__archiveDate {
        margin: 0;
        font-weight: var(--font-weight-semibold);
        color: get-color(--text-primary);
    }
}

.o-tooltipMenu-content {
    margin: 0 0 0 1rem;

    ul {
        width: 100% !important;
    }
}
