@import '~assets/sass/colors';

.m-projectReportIntroduction {
    flex-grow: 1;

    .MuiGrid-item {
        max-width: 508px;
    }

    &__head {
        color: get-color(--primary-dark);
        font-size: 62px;
        font-weight: bold;
        line-height: 0.81;
        text-transform: uppercase;
    }

    &__headSmall {
        color: get-color(--primary-dark);
        font-weight: bold;
        font-size: 31px;
        text-transform: uppercase;
    }

    &__sub {
        color: get-color(--text-primary);
        font-size: 20px;
        font-weight: bold;
        margin-top: 16px;
    }
}
