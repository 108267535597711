@import '~assets/sass/colors';

.m-summaryTable {
    th {
        background-color: transparent;
    }

    th,
    td {
        padding: 12px 10px;
        border: 1px solid get-color(--divider);
    }
}

.m-fundingTable {
    margin-top: 1em;
    width: 100%;
    margin-bottom: 24px;

    &__label {
        display: flex;
        align-items: center;

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__semiBold {
        font-weight: 600 !important;
    }

    th,
    td {
        border: 1px solid get-color(--divider);
    }

    th {
        background-color: get-color(--primary-main);
        color: get-color(--white-hsl);
    }
}
