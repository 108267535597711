@import '~assets/sass/colors';

.m-homePageHeader {
    &__name {
        font-size: 24px;
        color: get-color(--text-primary);
        margin-bottom: 40px;
    }

    &__subDescription {
        font-size: var(--font-size-normal);
        color: get-color(--text-primary);
        margin: 10px 0 16px 0;
        &.-dark {
            color: get-color(--black-hsl);
        }
    }

    &__activeProjectsButton {
        margin-bottom: 20px;
    }

    &__description {
        font-size: 20px;
        margin-bottom: 0;
        color: get-color(--text-primary);
        &.-light {
            color: get-color(--black-hsl);
            font-weight: 600;
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;

        button {
            line-height: 1;

            @media (min-width: 1006px) {
                line-height: 1.75;
            }
        }

        button:first-child {
            margin-right: 1em;
        }
    }
}
