@import '~assets/sass/colors';

.o-pageheading {
    margin-bottom: 24px !important;
    h1 {
        margin: 0px;
        width: fit-content;
        color: get-color(--text-primary);
    }
    p {
        margin: 5px 0 0 0;
        font-size: 14px;
        width: fit-content;
        color: get-color(--text-secondary);
    }
}
