@import '~assets/sass/colors';

.-o-identify-resources {
    &__closeIcon {
        .MuiIcon-root {
            font-size: 25px;
        }
    }
}

.o-identifyTable {
    border-radius: 0.5em;

    .MuiTableContainer-root {
        overflow-x: hidden;
    }

    .MuiPaper-rounded {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .MuiTableCell-body {
        padding-left: 10px !important;
    }
}

.m-resourceCategory {
    margin-top: 2rem;

    &__errorHeader {
        margin-bottom: 10px;
        width: fit-content;
        p {
            margin: 0;
            padding: 0;
        }
    }

    &__header {
        align-items: center;
        background-color: get-color(--primary-main);
        color: get-color(--white-hsl);
        display: flex;
        justify-content: space-between;
        padding: 1rem;

        &-name {
            display: flex;
            justify-content: flex-start;
            align-items: baseline;
        }
    }

    &__resourceCategory {
        margin: 0;
        padding: 10px;
    }

    &__name {
        align-items: center;
        display: flex;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 0.25rem;

        &-spaceLeft {
            padding-left: 0.5em;
        }
    }

    &__total {
        display: flex;
        align-items: baseline;

        p {
            padding-right: 0.5em;
        }

        strong {
            padding-right: 1.5em;
        }
    }

    &__popup {
        margin-left: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    &__addBtn {
        span {
            font-size: 14px;
        }
    }

    &__thead {
        background-color: get-color(--gray-800);

        th {
            font-weight: var(--font-weight-bold);
            font-size: 14px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }

    &__tbody {
        .a-tBodyRow {
            border-top: 1px solid get-color(--divider);

            &__interactive {
                cursor: pointer;
            }

            &__resourceLabel {
                color: get-color(--secondary-main);
                cursor: pointer;
            }

            .MuiAvatar-root {
                font-size: var(--font-size-tiny);
                height: 24px;
                width: 24px;
                border: 1px solid get-color(--gray-800);
            }

            &__editResourceIcon {
                color: get-color(--secondary-main);
                cursor: pointer;
                &:hover {
                    color: get-color(--secondary-dark);
                }
            }

            &:first-child {
                border-top: 0px;
            }

            td {
                font-size: 14px;
                border-right: 1px solid get-color(--divider);
            }

            td:first-child {
                // display: flex;
                font-size: 16px;
                font-weight: var(--font-weight-semibold);
                color: #539e93;
            }

            &__dollar {
                float: left;
            }

            &__total {
                font-weight: var(--font-weight-bold) !important;
                font-size: 16px !important;
            }

            &__status {
                display: flex;
                align-items: center;
                justify-content: center;

                span {
                    margin-left: 5px;
                }
            }
        }
    }

    &__empty {
        min-height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__modal {
        .m-modal {
            &__title {
                h2,
                span {
                    margin: 0;
                    font-size: 24px;
                    font-weight: var(--font-weight-bold);
                }
                p {
                    margin: 0;
                }
            }

            &__content {
                margin-bottom: 2rem;
            }

            &__actions {
                padding: 0 24px 21px 24px;
            }

            &__deleteBtn {
                background-color: get-color(--warning-light);
                margin-right: auto;
            }
            &__closeButton {
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }
}

.o-defineresources {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        flex-wrap: nowrap;

        button {
            line-height: 1;

            @media (min-width: 1123px) {
                line-height: 1.75;
            }
        }

        button:first-child {
            margin-right: 1em;
        }
    }

    .--toggled {
        pointer-events: none;
    }
}
