.Canvas {
    width: 100%;
    background: white;

    .ganttRectangle {
        fill: transparent;
    }
}

.gantt-graphline {
    stroke: rgba(0, 0, 0, 0.125);

    &.-selected {
        fill: rgba(0, 0, 0, 0.05);
    }
}
