@import '~assets/sass/colors';

.m-fundamentalsForm {
    &__parameterContainer {
        background-color: get-color(--white-hsl);
        border: 1px solid var(--primary-3);
        border-radius: 6px;
        box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    }

    &__nameContainer {
        margin-bottom: 14px;
    }

    &__hr {
        width: calc(100% + 32px);
        border-top: 1px solid get-color(--divider);
        border-bottom: none;
        margin: 18px 0px 14px -16px;
    }

    &__inputTitle {
        font-size: 16px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--text-primary);
        margin-bottom: 8px;
    }

    .MuiCardHeader-action {
        margin: 0;
    }

    &__nameFieldLabel {
        font-size: 16px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--black-hsl);
        margin-bottom: 12px;
    }

    &__inputDescription {
        font-size: var(--font-size-normal);
        color: get-color(--text-secondary);
        margin-bottom: 8px;
    }

    &__sectionHeading {
        padding: 12px;
        border-bottom: 1px solid get-color(--divider);
        span {
            font-size: var(--font-size-large);
            font-weight: var(--font-weight-semibold);
            color: get-color(--text-primary);
        }
    }

    .o-customInput {
        margin-bottom: 14px;
        &:last-child {
            margin-bottom: 0px;
        }
    }
}
