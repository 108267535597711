@import '~assets/sass/colors';

.m-createPlan {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: nowrap;
        button:first-child {
            margin-right: 1em;
        }
    }

    &__stakeholderList {
        list-style-type: none;
        padding: 0;
        width: 100%;
    }

    &__stakeholderItem {
        background: get-color(--white-hsl);
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 1px solid get-color(--divider);
        border-radius: 6px;
        padding: 6px 12px 6px 20px;
        margin-bottom: 1.25rem;
    }

    &__stakeholderNote {
        font-size: var(--font-size-tiny);
        color: get-color(--text-secondary);
    }

    &__stakeholderButton {
        &.-hasSearchError {
            margin-bottom: 23px !important;
        }
    }

    &__email {
        margin-right: 10px;
    }

    &__stakeholderInput {
        flex-grow: 1;
        border: none;
        pointer-events: none;
    }

    &__deleteButton {
        color: get-color(--error-main);
    }
}
