@import '~assets/sass/colors';

.a-avatarGroup {
    .MuiAvatarGroup-avatar {
        border: none;
    }

    .MuiAvatar-colorDefault {
        font-size: 14px;
        color: get-color(--gray-800);
        font-weight: var(--font-weight-semibold);
        border: 1px solid get-color(--gray-800);
        background-color: #e8e8e8;
    }

    &__avatarContainer {
        margin-left: -8px;
    }
}
