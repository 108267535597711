.m-stakeholder {
    width: 100%;
    padding: 0;
    margin: 0;
    flex-direction: column;

    &__item {
        align-items: center;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        padding: 12px;
        list-style-type: none;
        border-bottom: 1px solid var(--primary-3);

        * {
            margin: 0;
        }
    }

    &__nameEmailContainer {
        display: flex;
        flex-direction: column;
        flex-basis: 70%;
        flex-grow: 1;
        h4 {
            margin-left: 10px;
        }
    }

    &__nameContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__titleContainer {
        flex-grow: 1;
        flex-basis: 30%;
        text-align: left;
    }

    &__organization {
        margin-bottom: 10px;
        font-weight: var(--font-weight-semibold);
        color: get-color(--text-primary);
    }

    &__emailContainer {
        display: flex;
        align-items: center;
        margin-left: 48px;
        p {
            margin-left: 8px;
        }

        .MuiIcon-root {
            font-size: 18px;
        }
    }
}
