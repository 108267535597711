@import '~assets/sass/colors';

.m-fundamentalsIntroduction {
    flex-grow: 1;

    .MuiGrid-item {
        max-width: 508px;
    }

    &__head {
        font-size: 100px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 0.81;
        color: get-color(--primary-dark);
    }

    &__headSmall {
        font-size: 44px;
        text-transform: uppercase;
        font-weight: bold;
        color: get-color(--primary-dark);
    }

    &__stakeholderContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__stakeholderList {
        margin: 0 5px;
    }

    &__sub {
        font-size: 20px;
        font-weight: bold;
        color: get-color(--text-primary);
    }

    &__button {
        margin-top: 12px !important;
    }
}
