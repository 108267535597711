@import '~assets/sass/colors';

.m-taskGroupTable {
    margin-bottom: 20px;

    &__tablecontainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid var(--primary-5);
        border-radius: 6px;
        overflow: hidden;
    }

    &__description {
        grid-column: 1 / 1;
        grid-row: 2;
        padding-right: 20px;
    }

    &__content {
        background-color: white;
        padding: 8px 12px 16px !important;
        form {
            width: 100%;
        }
        ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
        }
    }
    > div > .MuiButtonBase-root {
        align-items: flex-start !important;
    }

    &__header {
        padding: 8px 10px 8px 0px;
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: auto auto;
        align-items: center;
        width: 100%;
        border-radius: 6px;
        -webkit-transition: max-height 1s ease-out;
        -moz-transition: max-height 1s ease-out;
        transition: max-height 1s ease-out;

        &.--expanded {
            border-radius: 6px 6px 0 0;
        }

        &.description-expanded {
            max-height: 500px;
            grid-template-rows: auto auto;
            .m-taskGroupTable__description {
                height: auto;
                overflow: visible;
                width: 400px;
                visibility: visible;
                p {
                    margin: 10px 0 5px 0;
                }
            }
        }
        .header-title {
            display: flex;
            align-items: center;
            font-size: var(--font-size-xlarge);
            font-weight: var(--font-weight-bold);

            .m-taskGroupTable__infoIcon {
                margin-left: 10px;
                &:hover {
                    color: get-color(--primary-main);
                }
            }
        }

        .header-tasksmapped {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            font-size: var(--font-size-xlarge);
            font-weight: var(--font-weight-semibold);
            .icon-container {
                margin-left: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background-color: black;
                .checkmark {
                    font-size: var(--font-size-tiny);
                }
            }
            .chevron {
                margin-left: 60px;
                margin-right: 10px;
                font-size: var(--font-size-xxlarge);
            }
        }
    }

    &__tabledata {
        animation: growOut 150ms ease-in-out forwards;
        width: 100%;
        .a-tasklistitem:last-child {
            border-radius: 0 0 1em 1em;
        }
    }

    .MuiAccordionSummary-expandIcon {
        width: 65px;
        height: 65px;
        padding: 0px;

        svg {
            width: 70%;
            height: 70%;
            color: black;
        }
    }

    @keyframes growOut {
        0% {
            transform: scaleY(0);
        }
        80% {
            transform: scaleY(0.9);
        }
        100% {
            transform: scaleY(1);
        }
    }

    @keyframes fadeInDown {
        0% {
            opacity: 0;
            display: none;
        }
        1% {
            opacity: 0;
            display: block;
        }
        100% {
            opacity: 1;
        }
    }
}
