@import '~assets/sass/colors';

.m-assessTechIntroduction {
    flex-grow: 1;

    .MuiGrid-item {
        max-width: 508px;
    }

    &__head {
        font-size: 93px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 0.81;
        color: get-color(--primary-dark);
    }

    &__headSmall {
        font-size: 47px;
        text-transform: uppercase;
        font-weight: bold;
        color: get-color(--primary-dark);
    }
}
