@import '~assets/sass/colors';

.m-planOfActionIntroduction {
    flex-grow: 1;

    .MuiGrid-item {
        max-width: 488px;
    }

    &__bods {
        max-width: 50%;
    }

    &__head {
        font-size: 102px;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 0.81;
        color: get-color(--primary-main);
    }

    &__headSmall {
        font-size: 50px;
        text-transform: uppercase;
        font-weight: bold;
        color: get-color(--primary-main);
    }

    &__sub {
        font-size: 20px;
        font-weight: bold;
        color: get-color(--text-primary);
    }

    &__button {
        margin-top: 12px !important;
    }
}
