@import '~assets/sass/colors';

.a-userDropdown {
    display: flex;
    align-items: center;
    .MuiAvatar-root {
        font-size: var(--font-size-normal);
        height: 30px;
        width: 30px;
        border: 1px solid get-color(--gray-800);
    }
}
