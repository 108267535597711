@import '~assets/sass/colors';

.a-buttonLink {
    color: get-color(--primary-main);
    display: flex;
    padding-left: 1em;

    &--text {
        text-decoration: underline;
        padding-left: 0.3em;
    }

    p {
        font-weight: 600;
    }
}

.calculator {
    .Header {
        justify-content: flex-end;
        margin-right: 18px;
    }

    .SectionsNav {
        display: none !important;
    }
}
