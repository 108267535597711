@keyframes fadeOutIn {
    0% {
        opacity: 0;
        transform: translateX(400px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeInOut {
    0% {
        opacity: 1;
        transform: translateX(0px);
    }
    100% {
        opacity: 0;
        transform: translateX(400px);
    }
}

.o-offcanvas {
    min-height: 100%;
    height: 100%;
    width: 400px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0px;
    margin-right: -400px;
    background-color: #fff;
    overflow-x: hidden;
    transition: margin-right 0.5s ease-in;
    padding-top: 60px;
    z-index: 200;

    padding: 25px 15px;
    box-shadow: -3px 0px 6px #00000029;
    animation-name: fadeInOut;
    animation-duration: 0.5s;

    &.--open {
        width: 400px;
        margin-right: 0px;
        animation-name: fadeOutIn;
        animation-duration: 0.5s;
    }

    .close-icon {
        position: absolute;
        top: 2px;
        right: 2px;
    }
}
