@import '~assets/sass/colors';

@keyframes growDown {
    0% {
        transform: scaleY(0);
    }
    80% {
        transform: scaleY(1.1);
    }
    100% {
        transform: scaleY(1);
    }
}

.m-menu {
    height: 100%;

    &__list {
        list-style: none;
        color: get-color(--white-hsl);
        padding: 0px;
        margin: 0px;
        height: 100%;
        width: 100%;

        &.horizontal_menu {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__projects {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: var(--font-weight-bold);
        width: 150px;
        height: 100%;
        margin-right: 20px;
        padding: 0 0 3px 0;
        font-weight: var(--font-weight-semibold);
        &:hover {
            background-color: rgb(255, 255, 255, 0.26);
        }
        &.-underlineMenuItem {
            border-bottom: 3px solid white;
            padding-bottom: 0px;
            font-weight: var(--font-weight-bold);
        }
    }

    &__notifications {
        display: flex;
        align-items: center;
        height: 100%;
        .MuiIcon-root {
            font-size: 35px;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 10px;
        height: 100%;
        z-index: 200;
        perspective: 1000px;

        // For Menu items with link prop
        a {
            height: 100%;
            color: get-color(--white-hsl);
            border-bottom: 0;
        }

        &.-hasSubMenu:hover {
            background-color: get-color(--primary-main);
            color: get-color(--white-hsl);

            // Dropdown chevron
            .bottom::after {
                margin-top: 5px;
                margin-bottom: 0;
                transform: rotate(-135deg);
                -webkit-transform: rotate(-135deg);
            }

            .m-menu__subMenuList {
                display: block;
                box-shadow: 0px 1px 3px get-color(--gray-400);
                border-radius: 0px 0px 8px 8px;
                background: get-color(--white-hsl) 0% 0% no-repeat padding-box;
                list-style: none;
                margin: 0px;
                padding: 0px;
                z-index: 9;
                min-width: 200px;
            }
        }

        // Dropdown chevron
        &.-hasSubMenu .bottom::after {
            content: ' ';
            border: solid get-color(--white-hsl);
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 3px;
            width: 3px;
            height: 3px;
            margin-left: 6px;
            margin-bottom: 2px;
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
    }

    &__subMenuList {
        position: absolute;
        top: 100%;
        right: 0;
        width: 100%;
        display: none;
        animation: growDown 300ms ease-in-out forwards;
        transform-origin: top center;
    }

    &__subMenuItem {
        width: 100%;
        border-bottom: 1px solid get-color(--gray-400);

        a {
            font-size: 18px;
            color: get-color(--primary-main);
            text-decoration: none;
            padding: 10px;
            border: none;

            @media (max-width: 1380px) {
                font-size: 14px;
            }

            &:active::after {
                content: '';
                display: block;
                border-bottom: 2px solid get-color(--secondary-main);
            }
        }

        &:hover {
            background-color: get-color(--gray-400);
        }

        &.-logout {
            font-size: 18px;
            text-align: left;
            justify-content: left;
            padding-left: 10px;
        }
    }

    &__subMenuItem:last-child {
        border-bottom: 0px;
        border-radius: 0 0 8px 8px;
    }
}

.m-profileMenu {
    color: get-color(--secondary-dark);

    .m-menu__subMenuItem {
        p {
            margin-top: 0;
        }
    }

    a:active::after {
        border-bottom: none;
    }

    &__details {
        padding: 10px 10px;
        &:hover {
            background-color: var(--white);
            cursor: default;
        }
    }

    &__name {
        font-size: 18px;
        font-weight: var(--font-weight-bold);
        padding: 0px 0px 0 0px;
        margin-bottom: 9px;

        @media (max-width: 1380px) {
            font-size: 14px;
        }
    }

    &__company {
        padding: 0 0px;
        margin-bottom: 6px;
        font-weight: var(--font-weight-semibold);

        @media (max-width: 1380px) {
            font-size: 12px;
        }
    }

    &__email,
    &__phone {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        color: #225b7e;
        span {
            margin-right: 5px;
        }
        a {
            padding: 0 0px;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &__techScout {
        display: inline-block;
        margin-right: 8px;
    }

    &__sbirNext {
        display: inline-block;
        margin-right: 8px;
    }
}
